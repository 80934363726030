import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;

const Loading = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    animation: spin 1s linear infinite;
    position: relative;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

type LoadingFreezeScreenProps = {
    isLoading: boolean;
}

export default function LoadingFreezeScreen( { isLoading }: LoadingFreezeScreenProps ) {

    return (
        <>
            {isLoading && (
                <Container>
                    <Loading />
                </Container>
            )}
        </>
    );
}