import styled from "styled-components"
import colors from "../../style/colors"
// import photo from "../../assets/images/background.png"
// import photo from "../../assets/images/background2.jpeg"

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    /* position: relative; */
`

export const ImageContainer = styled.div<{src?: string }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 273px;   

    background-image: url(${props => props.src});
    background-size: cover;
    /* background-size: contain; */
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 80px ${colors.darkGray};
`

export const HallContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 220px; */
    gap: 5px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    max-width: 70%;
`

export const HallTileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

export const HallTitle = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${colors.darkGray};
`

export const HallAddress = styled.span`
    font-size: 12px;
    color: ${colors.darkGray};
`

// export const ChildrenContainer = styled.div`
//     display: flex;
//     border-radius: 20px 20px 0 0;
//     padding: 40px 20px 20px 20px;
//     position: absolute;
//     width: 100%;    
   
//     height: 100%;
//     background-color: ${colors.white};
//     margin-top: -50px;
//     z-index: 0;
//     flex-direction: column;
//     gap: 20px;
//     border-top: 1px solid ${colors.lightGray};

//     @media (min-width: 768px) { /* Show on desktop */
//         max-width: 430px;
//     }
// `

export const ChildrenContainer = styled.div`
    position: relative;
    display: flex;
    border-radius: 20px 20px 0 0;
    padding: 40px 20px 20px 20px;
    width: 100%;
    /* height: 100%; */
    /* height: max-content; */
    background-color: ${colors.white};
    margin-top: -50px;
    z-index: 0;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid ${colors.lightGray};
    
    @media (min-width: 768px) { /* Show on desktop */
        top: -40px;    
        max-width: 430px;
        margin: 0 auto; /* Center the container */
        /* max-height: 50vh; */
    }
`