import React, { useEffect } from "react"
import {  FaRegCalendarAlt, FaStore, FaTag} from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import MediumText from "../../../components/texts/MediumText"
import NormalText from "../../../components/texts/NormalText"
import colors from "../../../style/colors"
import { GridMenuWithTreeColumns, ItemContainer, MenuContainer } from "../../globalStyles"
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../hooks/LoadingHook"

export default function LogedHome() {
    const navigate = useNavigate();
    const { isLoading } = LoadingHook()

    useEffect(() => {
        localStorage.removeItem('selectedDate')
    }, [])
    
    const handleNavigate = (path: string) => {
        navigate(path)
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MenuContainer>                
                <MediumText color={colors.mediumGray}>Menu</MediumText>
                <GridMenuWithTreeColumns>
                <ItemContainer onClick={() => handleNavigate('barber/reservas')}>
                        <FaRegCalendarAlt size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Agenda</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={() => handleNavigate('barber/ausencias')}>
                        <FaTag size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Ausencias</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={() => handleNavigate('barbearias')}>
                        <FaStore size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Barbearia</NormalText>
                    </ItemContainer>
                </GridMenuWithTreeColumns>
            </MenuContainer>
        </>
    )
}