import React, { useEffect } from "react"
import { AuthContext } from "../../../context/authContext"
import colors from "../../../style/colors"
import NormalText from "../../../components/texts/NormalText"
import { GoBackButton, LabelAndValueContainer } from "../../globalStyles"
import { ConfirmationButton, InputValue } from "../../DoningReservation/style"
import MediumText from "../../../components/texts/MediumText"
import styled from "styled-components"
import { api } from "../../../config/api"
import { toast } from "react-toastify"
import { BarbeariaType, BarberShopPhotoType } from "../../../types/barbershop"
import { useNavigate, useParams } from "react-router-dom"
import { FaStore } from "react-icons/fa"
import { BarbershopContext } from "../../../context/babershop"
import { LoadingHook } from "../../../hooks/LoadingHook"

const PhotoImageDiv = styled.div<{ src: string, fullSize?: boolean }>`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: ${props => props.fullSize ? "400px" : "200px"};
    max-height: ${props => props.fullSize ? "400px" : "200px"};
    border-radius: 5px;
    object-fit: cover;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${props => props.src});
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease;
    }

    &:hover:before {
        cursor: pointer;
        transform: scale(1.1);
    }

    &:active {
        outline: 4px solid ${colors.gray};
    }
`

export const BackgoundModalContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (min-width: 768px) { /* Show on desktop */
        align-items: center;
    }
`

const UpdatePhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 20px 20px 0 0;

    @media (min-width: 768px) { /* Show on desktop */
        max-width: 430px;
        border-radius: 20px;
    }
`
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 1rem;
`

const UploadPhotoInput = styled.input`
    display: none;
`

const NoPhotoContainer = styled.div<{ fullSize?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props => props.fullSize ? "400px" : "200px"};
    max-height: ${props => props.fullSize ? "400px" : "200px"};
    border-radius: 5px;
    background-color: ${colors.lightGray};
    transition: background-color 0.3s ease;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        outline: 4px solid ${colors.gray};
    }
`


export default function BarberShopDetails() {
    const { id_barbearia } = useParams()
    const [barberShopUpdate, setBarberShopUpdate] = React.useState<BarbeariaType>()
    const [photo, setPhoto] = React.useState<string>('')
    const { barberShopPhoto ,setBarberShopPhoto, setBarbershop } = React.useContext(BarbershopContext)
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const navigate = useNavigate()
    const {setIsLoading, hideLoadingWithDelay} = LoadingHook()

    useEffect(() => {
        setIsLoading(true)
        async function getBarbearia() {
            try {
                const response = await api.get(`/barbearias/${id_barbearia}`)
                if (response.data){
                    setBarberShopUpdate(response.data)
                    const responsePhoto = await api.get(`/barbearias_foto/${id_barbearia}`)
                    if (responsePhoto.data) {
                        setPhoto(process.env.REACT_APP_API_URL + responsePhoto.data.foto)
                        setBarberShopPhoto(responsePhoto.data)
                    }
                }
            } catch (error) {
            } finally {
                hideLoadingWithDelay()
            }
        }
        getBarbearia()
    }, [id_barbearia])

    const uploadPhoto = async (file: File) => {
        const formData = new FormData()
        formData.append("files", file)

        //post
        if (!barberShopPhoto?.id) {
            const response = await api.post("/upload_files/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            const full_path_photo = response?.data[0].full_path
            if (full_path_photo) {
                const barber_photo_data = {
                    barbearia_id: id_barbearia,
                    foto: full_path_photo
                }
                const response = await api.post("/barbearias_foto/", barber_photo_data)
                if (response.status === 200) {
                    setPhoto(process.env.REACT_APP_API_URL + full_path_photo)
                    setBarberShopPhoto(response.data)
                    setModalIsOpen(false)
                    toast.success("Foto criada com sucesso")
                }
            }
        }

        // update
        else {
            const response = await api.delete(`/upload_files/`, {
                data: {
                    file_path: barberShopPhoto.foto
                }
            })

            if (response.status === 200) {
                const response = await api.post("/upload_files/", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                const full_path_photo = response?.data[0].full_path
                if (full_path_photo) {
                    const barber_photo_data = {
                        id: barberShopPhoto.id,
                        barbearia_id: id_barbearia,
                        foto: full_path_photo
                    }
                    const response = await api.put(`/barbearias_foto/${barberShopPhoto.id}`, barber_photo_data)
                    if (response.status === 200) {
                        setPhoto(process.env.REACT_APP_API_URL + full_path_photo)
                        setBarberShopPhoto(response.data)
                        setModalIsOpen(false)
                        toast.success("Foto atualizada com sucesso")
                    }
                }
            }
        }
    }

    const handleUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSaveButton = async () => {
        try {
            const response = await api.put(`/barbearias/`, barberShopUpdate)
            if (response.status === 200) {
                if (barberShopUpdate) {
                    setBarbershop(barberShopUpdate)
                }
                toast.success("Barbearia atualizada com sucesso")
                navigate(-1)
            }
        } catch (error) {
            toast.error("Erro ao atualizar barbearia")
        }   
    }

    return (
        <>
            {modalIsOpen && (
                <BackgoundModalContainer >
                    <UpdatePhotoContainer>
                        {photo ? (
                            <PhotoImageDiv
                                src={photo}
                                onClick={handleUploadButtonClick}
                                fullSize
                            />
                        ) : (
                            <NoPhotoContainer
                                fullSize
                                onClick={handleUploadButtonClick}
                            >
                                <FaStore size={30} color={colors.darkGray} />
                                <NormalText bold color={colors.darkGray}>Você não possui foto</NormalText>
                            </NoPhotoContainer>
                        )}
                        <ConfirmationButton onClick={handleUploadButtonClick}>
                            <UploadPhotoInput
                                type="file"
                                ref={fileInputRef}
                                onChange={(e) => {
                                    if (e.target.files) {
                                        uploadPhoto(e.target.files[0])
                                    }
                                }}
                            />
                            <MediumText color="white" bold>Selecione sua foto</MediumText>
                        </ConfirmationButton>

                        <GoBackButton onClick={() => setModalIsOpen(false)}>
                            <NormalText bold>Voltar</NormalText>
                        </GoBackButton>
                    </UpdatePhotoContainer>
                </BackgoundModalContainer>
            )}

            {photo ? (
                <PhotoImageDiv
                    src={photo}
                    onClick={() => setModalIsOpen(true)}
                />
            ) : (
                <NoPhotoContainer
                    onClick={() => setModalIsOpen(true)}
                >
                    <FaStore size={30} color={colors.darkGray} />
                    <NormalText bold color={colors.darkGray}>Adicione uma foto</NormalText>
                </NoPhotoContainer>
            )}


            {barberShopUpdate?.id && (
                <>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>ID</NormalText>
                        <InputValue
                            type="text"
                            value={barberShopUpdate.id}
                            disabled
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                        <InputValue
                            type="text"
                            value={barberShopUpdate?.nome}
                            onChange={(e) => setBarberShopUpdate({ ...barberShopUpdate, nome: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                        <InputValue
                            type="text"
                            value={barberShopUpdate?.telefone}
                            onChange={(e) => setBarberShopUpdate({ ...barberShopUpdate, telefone: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Endereço</NormalText>
                        <InputValue
                            type="text"
                            value={barberShopUpdate?.endereco}
                            onChange={(e) => setBarberShopUpdate({ ...barberShopUpdate, endereco: e.target.value })}
                        />
                    </LabelAndValueContainer>
                </>
            )
            }

            <ConfirmationButton
                onClick={handleSaveButton}
            >
                <MediumText color="white" bold>Salvar</MediumText>
            </ConfirmationButton>
            <GoBackButton onClick={() => navigate(-1)}>
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>
        </>
    )
}