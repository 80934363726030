import React from "react";
import LoadingFreezeScreen from "../../components/LoadingFreezeScreen";
import { LabelAndValueContainer } from "../globalStyles";
import NormalText from "../../components/texts/NormalText";
import { ConfirmationButton, InputValue } from "../DoningReservation/style";
import { LoadingHook } from "../../hooks/LoadingHook";
import colors from "../../style/colors";
import MediumText from "../../components/texts/MediumText";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const { signIn } = React.useContext(AuthContext)
    const navigate = useNavigate()

    React.useEffect(() => {
        setIsLoading(true)
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLogin = async () => {
        setIsLoading(true)
        try {
            await signIn(email, password)
            navigate('/')
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }


    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Email</NormalText>
                <InputValue
                    type="text"
                    placeholder="teste@teste.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Senha</NormalText>
                <InputValue
                    type="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </LabelAndValueContainer>
            <ConfirmationButton
                onClick={handleLogin}
            >
                <MediumText color="white" bold>Entrar</MediumText>
            </ConfirmationButton>
        </>
    )
}