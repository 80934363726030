import React from "react";
import styled from "styled-components";

type Props = {
    bold?: boolean;
    children?: React.ReactNode;
    color?: string;
}

type PropsStyled = {
    bold?: boolean;
    color?: string;
}

const Text = styled.span<PropsStyled>`
    font-size: 10px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    color: ${props => props.color};
    /* word-wrap: break-word; */
`

export default function SmallText({children, bold=false, color}: Props) {
    return (
        <Text bold={bold} color={color}>
            {children}
        </Text>
    );
}