import React from 'react';
import './style/global.css'
import GeneralRoutes from './routes/generalRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import dotenv from 'dotenv';
// dotenv.config();

const styles = {
  marginTop: '60px',
  // padding: '20px',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  
}


function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover 
        style={styles}
      />
      {/* <BackgroundContainer> */}
        {/* <MainContainer> */}
          <GeneralRoutes />
        {/* </MainContainer> */}
      {/* </BackgroundContainer> */}
    </>
  );
}

export default App;
