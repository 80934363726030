import { useState } from "react"

export function LoadingHook() {
    const [isLoading, setIsLoading] = useState(false)

    const hideLoadingWithDelay = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 300)
    }

    return {
        isLoading,
        setIsLoading,
        hideLoadingWithDelay
    }
}