import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import colors from "../../style/colors";

const BackgroundModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const Modal = styled.div`
    background-color: ${colors.white};
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 370px;
    max-height: 80vh;
    overflow-y: auto;
`;

type ButtonModalProps = {
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
};

export default function GenericModal({ children, open, onClose }: ButtonModalProps) {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, onClose]);

    return (
        <>
            {open && (
                <BackgroundModal>
                    <Modal ref={modalRef}>
                        {children}
                    </Modal>
                </BackgroundModal>
            )}
        </>
    );
}