import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BarbershopContext } from "../../../../context/babershop"
import { ServicesType, ServiceType } from "../../../../types/services"
import { RecurrentReservationContext } from "../../../../context/RecurrentReservation"
import { api } from "../../../../config/api"
import { LabelAndValueContainer, ReservationButton, Separator, Service, Services, ServiceValueAndTimeContainer, TotalContainer, TotalsContainer, TotalWithReservationButtonContainer } from "../../../globalStyles"
import NormalText from "../../../../components/texts/NormalText"
import colors  from "../../../../style/colors"
import MediumText from "../../../../components/texts/MediumText"
import SmallText from "../../../../components/texts/SmallText"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../../hooks/LoadingHook"


export default function SelectServiceRecurrent() {
    const navigate = useNavigate()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const [services, setServices] = useState<ServicesType>([])
    const recurrentReservationContext = useContext(RecurrentReservationContext);
    const {
        selectedServices,
        setSelectedServices,
    } = recurrentReservationContext;
    // const inprogressReservationContext = useContext(InprogressReservationContext)
    // const { selectedServices, setSelectedServices, total } = inprogressReservationContext

    
    useEffect(() => {
        const getServices = async () => {
            try {
                const response = await api.get(`/servicos/barbearia/${barbershop?.id}`)
                setServices(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getServices()
        hideLoadingWithDelay()
    }, [barbershop])

    const handleSelectService = (service: ServiceType) => {
        if (selectedServices.some(selectedService => selectedService.id === service.id)) {
            setSelectedServices(selectedServices.filter(selectedService => selectedService.id !== service.id))
            return
        }

        setSelectedServices([...selectedServices, service])
    }

    const handleNavigateToSelectService = () => {
        navigate(-1)
    }

    const isSelected = (service: ServiceType) => {
        return selectedServices.some(selectedService => selectedService.id === service.id)
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Serviços</NormalText>
                <Services>
                    {services.map((service, index) => (
                        <Service key={index} onClick={
                            () => handleSelectService(service)
                        } isSelected={selectedServices.some(selectedService => selectedService.id === service.id)}>
                            <NormalText color={colors.mediumGray} bold>{service.nome}</NormalText>
                            <ServiceValueAndTimeContainer>
                                <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                            </ServiceValueAndTimeContainer>
                        </Service>
                    ))}
                </Services>
                {/* <Services>
                    {services.map((service, index) => (
                        <Service key={index} onClick={
                            () => handleSelectService(service)
                        } isSelected={isSelected(service)}>
                            <NormalText color={isSelected(service) ? colors.white : colors.mediumGray} bold>{service.nome}</NormalText>
                            <ServiceValueAndTimeContainer>
                                <MediumText color={isSelected(service) ? colors.white : colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                <NormalText color={isSelected(service) ? colors.white : colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                            </ServiceValueAndTimeContainer>
                        </Service>
                    ))}
                </Services> */}
            </LabelAndValueContainer>
            <Separator />
            <TotalWithReservationButtonContainer>
                {/* <TotalsContainer>
                    <TotalContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray}>Tempo estimado</SmallText>
                            <NormalText color={colors.darkGray} bold>{total.estimatedTime}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray}>Valor Total</SmallText>
                            <NormalText color={colors.darkGray} bold>R$ {total.total}</NormalText>
                        </LabelAndValueContainer>
                    </TotalContainer>
                </TotalsContainer> */}
                <ReservationButton onClick={handleNavigateToSelectService}>
                    <NormalText color={colors.white} bold>Selecionar serviços</NormalText>
                </ReservationButton>
            </TotalWithReservationButtonContainer>
        </>
    );
}