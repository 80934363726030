import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BarbeariaType } from "../../../types/barbershop"
import { api } from "../../../config/api"
import { GridMenuWithTreeColumns, ItemContainer, LabelAndValueContainer, MenuContainer } from "../../globalStyles"
import colors from "../../../style/colors"
import NormalText from "../../../components/texts/NormalText"
import { InputValue } from "../../DoningReservation/style"
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../hooks/LoadingHook"
import MediumText from "../../../components/texts/MediumText"
import { FaBriefcase, FaRegCalendarAlt, FaRegClock, FaStore, FaUser } from "react-icons/fa"

export default function BarberShopHome() {
    const { id_barbearia } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>({
        telefone: "",
        data_criacao: "",
        nome: "",
        endereco: "",
        id: 0
    }
    )
    const navigate = useNavigate()

    React.useEffect(() => {
        const getBarberShop = async () => {
            try {
                const response = await api.get(`/barbearias/${id_barbearia}`)
                setBarberShop(response.data)
            } catch (error) {
            }
        }

        getBarberShop()
    }, [id_barbearia])

    const handleNavigate = (path: string) => {
        navigate(path)
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MenuContainer>
                <MediumText bold color={colors.darkGray}>Barbearias</MediumText>
                <MediumText color={colors.mediumGray}>Menu</MediumText>
                <GridMenuWithTreeColumns>
                    <ItemContainer onClick={() => handleNavigate('horarios')}>
                        <FaRegClock size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Horário de atendimento</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={() => handleNavigate('servicos')}>
                        <FaBriefcase size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Serviços</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={() => handleNavigate('barbeiros')}>
                        <FaUser size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>barbeiros</NormalText>
                    </ItemContainer> 
                    <ItemContainer onClick={() => handleNavigate(`detalhes`)}>
                        <FaStore size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Detalhes da barbearia</NormalText>
                    </ItemContainer>                   
                </GridMenuWithTreeColumns>
            </MenuContainer>
        </>
    )
}