import React, { useEffect } from "react"
import { AuthContext } from "../../../../context/authContext"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import { useNavigate, useParams } from "react-router-dom"
import { BarbersType } from "../../../../types/barber"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import MediumText from "../../../../components/texts/MediumText"
import { BarbershopContext } from "../../../../context/babershop"
import { api } from "../../../../config/api"
import GenericButton from "../../../../components/GenericButton"
import { ResumeContainer } from "../../../DoningReservation/style"
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles"
import colors from "../../../../style/colors"
import SmallText from "../../../../components/texts/SmallText"
import NormalText from "../../../../components/texts/NormalText"

export default function BarbersList() {
    const { id_barbearia } = useParams()
    const { user } = React.useContext(AuthContext)
    const { barbershop } = React.useContext(BarbershopContext)
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const navigate = useNavigate()
    const [barbers, setBarbers] = React.useState<BarbersType>([])

    useEffect(() => {
        setIsLoading(true)

        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${id_barbearia}`)
                setBarbers(response.data)
            } catch (error) {
            }
        }

        getBarbers()
        hideLoadingWithDelay()
    }, [])

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Barbeiros</MediumText>

            <GenericButton onClick={() => navigate('convite')}>
                Adicionar um novo barbeiro
            </GenericButton>

            {barbers.map(barber => (
                <ResumeContainer key={barber.id} onClick={() => navigate(`barbeiro/${barber.id}`)}>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Nome</SmallText>
                        <NormalText color={colors.mediumGray}>{barber.nome}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Telefone</SmallText>
                        <NormalText color={colors.mediumGray}>{barber.telefone}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Email</SmallText>
                        <NormalText color={colors.mediumGray}>{barber.email}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>CPF</SmallText>
                        <NormalText color={colors.mediumGray}>{barber.cpf}</NormalText>
                    </LabelAndValueContainer>
                </ResumeContainer>
            ))}
            <GoBackButton onClick={() => navigate(-1)}>
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>
        </>
    )
}