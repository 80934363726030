import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { api } from "../../../../config/api"
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles"
import colors from "../../../../style/colors"
import NormalText from "../../../../components/texts/NormalText"
import { ConfirmationButton, InputValue } from "../../../DoningReservation/style"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import { toast } from "react-toastify"
import { AuthContext } from "../../../../context/authContext"
import { OpeningHourType } from "../../../../types/barbershop"

const days = [
    { value: 1, label: "Segunda-feira" },
    { value: 2, label: "Terça-feira" },
    { value: 3, label: "Quarta-feira" },
    { value: 4, label: "Quinta-feira" },
    { value: 5, label: "Sexta-feira" },
    { value: 6, label: "Sábado" },
    { value: 7, label: "Domingo" }
]

export default function OpeningHour() {
    const navigate = useNavigate()
    const { id_horario, dia } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const { user } = React.useContext(AuthContext)
    const [openingHour, setOpeningHour] = React.useState<OpeningHourType>({
        id: 0,
        dia_semana: 0,
        hora_abertura: "",
        hora_fechamento: "",
        barbearia_usuario_id: 0,
        data_criacao: ""
    }
    )

    React.useEffect(() => {
        const getOpeningHour = async () => {
            const response = await api.get(`/horarios_atendimento/${id_horario}`)
            setOpeningHour(response.data)
        }
        if (id_horario) {
            try {
                setIsLoading(true)
                getOpeningHour()
            } catch (error) {
                toast.error("Erro ao criar Horario")
            } finally {
                hideLoadingWithDelay()
            }
        }
    }, [id_horario])

    const handleConfirm = async () => {
        setIsLoading(true)
        if (id_horario) {
            // update
            try {
                const response = await api.put('/horarios_atendimento/', openingHour)
                if (response.status === 200 || response.data.id) {
                    toast.success("Horario atualizado com atualizado com sucesso")
                    navigate(-1)
                } else {
                    toast.error("Erro ao criar Horario")
                }
            }
            catch (error) {
                toast.error("Erro ao criar Horario")
            }

        } else {
            try {
                const create_opening = {
                    dia_semana: dia,
                    hora_abertura: openingHour.hora_abertura,
                    hora_fechamento: openingHour.hora_fechamento,
                    barbearia_usuario_id: user?.id
                }
                const response = await api.post('/horarios_atendimento/', create_opening)
                if (response.status === 201 || response.data.id) {
                    toast.success("Horário de atendimento criado com sucesso")
                    navigate(-1)
                } else {
                    toast.error("Erro ao criar Horario")
                }
            }
            catch (error) {
                toast.error("Erro ao criar Horario")
            }
        }
        hideLoadingWithDelay()
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <NormalText bold>Horário de atendimento</NormalText>
            {openingHour?.id === 0 ?
                <NormalText bold>Novo horário de atendimento</NormalText>
                :
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>ID</NormalText>
                    <InputValue
                        type="text"
                        value={openingHour?.id}
                        disabled
                    />
                </LabelAndValueContainer>
            }

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Dia da semana</NormalText>
                <InputValue
                    type="text"
                    // value={openingHour?.dia_semana || dia || ''}
                    value={days.find(day => day.value === Number(dia))?.label}
                    disabled
                // onChange={(e) => setOpeningHour({ ...openingHour, dia_semana: Number(e.target.value) })}
                />
            </LabelAndValueContainer>

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Horário de abertura</NormalText>
                <InputValue
                    type="time"
                    value={openingHour?.hora_abertura}
                    onChange={(e) => setOpeningHour({ ...openingHour, hora_abertura: e.target.value })}
                />
            </LabelAndValueContainer>

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Horário de fechamento</NormalText>
                <InputValue
                    type="time"
                    value={openingHour?.hora_fechamento}
                    onChange={(e) => setOpeningHour({ ...openingHour, hora_fechamento: e.target.value })}
                />
            </LabelAndValueContainer>


            <ConfirmationButton
                onClick={handleConfirm}
            >
                <NormalText color="white" bold>Salvar</NormalText>
            </ConfirmationButton>
            <GoBackButton
                onClick={() => navigate(-1)}
            >
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>

        </>
    )
}