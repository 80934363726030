import React from "react";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import MediumText from "../../../../components/texts/MediumText";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/authContext";
import { BarbeariasType } from "../../../../types/barbershop";
import { api } from "../../../../config/api";
import { ResumeContainer } from "../../../DoningReservation/style";
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles";
import SmallText from "../../../../components/texts/SmallText";
import colors from "../../../../style/colors";
import NormalText from "../../../../components/texts/NormalText";
import { ServicesType } from "../../../../types/services";
import { BarbershopContext } from "../../../../context/babershop";
import GenericButton from "../../../../components/GenericButton";

export default function ServiceList() {
    const { id_barbearia } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [services, setServices] = React.useState<ServicesType>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        setIsLoading(true)

        const getBarbershops = async () => {
            try {
                const response = await api.get(`/servicos/barbearia/${id_barbearia}`)
                setServices(response.data)
            } catch (error) {
            }
        }

        getBarbershops()
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Barbearias</MediumText>

            <GenericButton onClick={() => navigate('servico')}>
                Adicionar novo serviço
            </GenericButton>

            {services.map(service => (
                <ResumeContainer key={service.id} onClick={() => navigate(`servico/${service.id}`)}>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Nome</SmallText>
                        <NormalText color={colors.mediumGray}>{service.nome}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Descrição</SmallText>
                        <NormalText color={colors.mediumGray}>{service.descricao}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Preço</SmallText>
                        <NormalText color={colors.mediumGray}>{service.preco}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Tempo estimado</SmallText>
                        <NormalText color={colors.mediumGray}>{service.tempo_estimado}</NormalText>
                    </LabelAndValueContainer>
                </ResumeContainer>
            ))}
            <GoBackButton onClick={() => navigate(-1)}>
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>
        </>
    )
}