
const colors = {
    lightGray: '#f5f5f5',
    gray: '#e5e5e5',
    white: '#ffffff',
    mediumGray: '#9a9a9a',
    darkGray: '#333333',
    red: '#9d0208',
    lightRed: '#c32530',
    blue: '#2381e0',
    blueHover: '#1565c0',
    green: '#4caf50',
    black: '#000000',
}

export default colors