import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import { BarbershopContext } from "../../../../context/babershop"
import { BarberType } from "../../../../types/barber"
import { toast } from "react-toastify"
import { api } from "../../../../config/api"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import MediumText from "../../../../components/texts/MediumText"
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles"
import SmallText from "../../../../components/texts/SmallText"
import NormalText from "../../../../components/texts/NormalText"
import colors from "../../../../style/colors"
import GenericButton from "../../../../components/GenericButton"
import { InputValue } from "../../../DoningReservation/style"
import { AxiosError, AxiosResponse } from "axios"

export default function InviteBarber() {
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    // const { user } = React.useContext(AuthContext)
    const { barbershop } = React.useContext(BarbershopContext)
    const navigate = useNavigate()
    const [inviteBarber, setInviteBarber] = React.useState('')

    const handleConfim = async () => {
        setIsLoading(true)
        if (inviteBarber) {
            // update
            try {
                const response = await api.post('/barbeiros/convite/', { email: inviteBarber, barbearia_id: barbershop?.id })
                if (response.status === 200 || response.data.id) {
                    toast.success("Barbeiro convidado com sucesso")
                    navigate(-1)
                }
            } catch (error: any) {
                toast.error(error.response.data.detail)
            } finally {
                hideLoadingWithDelay()
            }
        }
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Convide um barbeiro</MediumText>

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                <InputValue
                    type="text"
                    value={inviteBarber}
                    onChange={(e) => {
                        setInviteBarber(e.target.value)
                    }}
                />
            </LabelAndValueContainer>

            <GenericButton onClick={handleConfim}>
                Confirmar
            </GenericButton>
            <GoBackButton
                onClick={() => navigate(-1)}
            >
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>
        </>
    )
}
