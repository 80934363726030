import axios from 'axios';

export const api_url = process.env.REACT_APP_API_URL || '';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:8000/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});