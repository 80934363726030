import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { BarbershopContext } from "../../context/babershop"
import LoadingFreezeScreen from "../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../hooks/LoadingHook"
import { AuthContext } from "../../context/authContext"
import LogedHome from "./LogedHome"
import UnlogedHome from "./UnlogedHome"

export default function Home() {
    const navigate = useNavigate();
    const barberShopContext = useContext(BarbershopContext)
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const {user} = React.useContext(AuthContext);

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            {user?.id ? (<LogedHome />) : (<UnlogedHome />)}            
        </>
    )
}