import styled from "styled-components"
import colors from "../../style/colors"
import { keyframes, css } from 'styled-components';

const slideDown = keyframes`
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
`;


export const Container = styled.div`
    width: 100%;
    color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid ${colors.lightGray};
    user-select: none;
    /* z-index: 2; */
    /* position: relative; */

`

export const MenuIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* background-color: ${colors.lightGray}; */
    border: 1px solid ${colors.lightGray};
    border-radius: 5px;
    transition: background-color 0.3s;


    &:hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
    }

    &:active {
        outline: 1px solid ${colors.gray};
    }
`

export const LogoNameFullContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
`

export const LogoNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
    }

    &:active {
        outline: 1px solid ${colors.gray};
    }
`

export const Name = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${colors.mediumGray};
`

export const BackgroundMenuContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* top: 0; */
    /* left: 0; */
    z-index: 1;

    @media (min-width: 768px) { /* Show on desktop */
        display: none;
    }
`

export const MenuContainer = styled.div<{open: boolean}>`
    display: flex;
    position: absolute;
    /* position: relative; */
    /* top: 0; */
    /* top: 50px; */
    width: 100%;
    background-color: ${colors.white};
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    gap: 10px;
    user-select: none;

    @media (min-width: 768px) { /* Show on desktop */
        max-width: 430px;
        margin: 0 auto; /* Center the container */
    }
`

// export const MenuContainer = styled.div<{open: boolean}>`
//     display: flex;
//     position: absolute;
//     top: 50px;
//     width: 100%;
//     background-color: ${colors.white};
//     z-index: 1;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding: 10px;
//     border-radius: 0 0 10px 10px;
//     gap: 10px;
//     user-select: none;
//     animation: ${props => props.open ? css`${slideDown} 0.5s ease forwards` : 'none'};

//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         /* background: rgba(0, 0, 0, 0.1); */
//         opacity: ${props => props.open ? 1 : 0};
//         transition: opacity 0.3s ease;
//     }
// `;

export const MenuItemContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border: 1px solid ${colors.lightGray};
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }
`

export const MenuItem = styled.span`
    font-size: 14px;
    color: ${colors.mediumGray};
`