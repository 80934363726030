import React from "react";
import { ServicesType } from "../types/services";
import { RecurrentReservationsScheduleType, RecurrentReservationType } from "../types/reservation";
import { BarbersType, BarberType } from "../types/barber";
import { NonRegistredClientType } from "./inProgressReservation";

type RecurrentReservationValuesType = {
    selectedServices: ServicesType;
    initialDate: Date;
    finalDate: Date;
    recurrentReservationType: RecurrentReservationType;
    recurrentReservationsSchedules: RecurrentReservationsScheduleType;
    dayOfWeekPreference: number;
    hourPreference: string;
    // barberPreference: BarberType;
    barberPreference: BarbersType;
    nonRegistredClient: NonRegistredClientType;
    setSelectedServices: (services: ServicesType) => void;
    setInitialDate: (date: Date) => void;
    setFinalDate: (date: Date) => void;
    setRecurrentReservationType: (recurrentReservationType: RecurrentReservationType) => void;
    setRecurrentReservationsSchedules: (recurrentReservationsSchedules: RecurrentReservationsScheduleType) => void;
    setDayOfWeekPreference: (dayOfWeekPreference: number) => void;
    setHourPreference: (hourPreference: string) => void;
    // setBarberPreference: (barberPreference: BarberType) => void;
    setBarberPreference: (barberPreference: BarbersType) => void;
    setNonRegistredClient: (client: NonRegistredClientType) => void;

    resetValuesOfContext: () => void;
}

export const RecurrentReservationContext = React.createContext<
RecurrentReservationValuesType
>({
    selectedServices: [],
    initialDate: new Date(),
    finalDate: new Date(),
    recurrentReservationType: { id: 0, nome: '' },
    recurrentReservationsSchedules: [],
    dayOfWeekPreference: 0,
    hourPreference: '',
    // barberPreference: {
    //     id: 0,
    //     nome: '',
    //     telefone: '',
    //     email: '',
    //     cpf: '',
    //     data_criacao: '',
    //     barbearia_usuario_id: 0
    // },
    barberPreference: [],
    nonRegistredClient: {
        id: 0,
        nome: '',
        telefone: '',
        email: ''
    },
    setSelectedServices: () => {},
    setInitialDate: () => {},
    setFinalDate: () => {},
    setRecurrentReservationType: () => {},
    setRecurrentReservationsSchedules: () => {},
    setDayOfWeekPreference: () => {},
    setHourPreference: () => {},
    setBarberPreference: () => {},
    setNonRegistredClient: () => {},
    resetValuesOfContext: () => {}
});

export function RecurrentReservationProvider({
    children
}: {
    children: React.ReactNode;
}) {
    const [selectedServices, setSelectedServices] = React.useState<ServicesType>([]);
    const [initialDate, setInitialDate] = React.useState(new Date());
    const [finalDate, setFinalDate] = React.useState(new Date());
    const [recurrentReservationType, setRecurrentReservationType] = React.useState<RecurrentReservationType>({ id: 0, nome: '' });
    const [recurrentReservationsSchedules, setRecurrentReservationsSchedules] = React.useState<RecurrentReservationsScheduleType>([]);
    const [dayOfWeekPreference, setDayOfWeekPreference] = React.useState<number>(0);
    const [hourPreference, setHourPreference] = React.useState<string>('');
    // const [barberPreference, setBarberPreference] = React.useState<BarberType>({
    //     id: 0,
    //     nome: '',
    //     telefone: '',
    //     email: '',
    //     cpf: '',
    //     data_criacao: '',
    //     barbearia_usuario_id: 0
    // });
    const [barberPreference, setBarberPreference] = React.useState<BarbersType>([]);
    const [nonRegistredClient, setNonRegistredClient] = React.useState<NonRegistredClientType>({
        id: 0,
        nome: '',
        telefone: '',
        email: ''
    });

    const resetValuesOfContext = () => {
        setSelectedServices([]);
        setInitialDate(new Date());
        setFinalDate(new Date());
        setRecurrentReservationType({ id: 0, nome: '' });
        setRecurrentReservationsSchedules([]);
        setDayOfWeekPreference(0);
        setHourPreference('');
        // setBarberPreference({
        //     id: 0,
        //     nome: '',
        //     telefone: '',
        //     email: '',
        //     cpf: '',
        //     data_criacao: '',
        //     barbearia_usuario_id: 0
        // });
        setBarberPreference([]);
        setNonRegistredClient({
            id: 0,
            nome: '',
            telefone: '',
            email: ''
        });
    }

    
    return (
        <RecurrentReservationContext.Provider
            value={{
                selectedServices,
                initialDate,
                finalDate,
                recurrentReservationType,
                recurrentReservationsSchedules,
                dayOfWeekPreference,
                hourPreference,
                barberPreference,
                nonRegistredClient,
                setSelectedServices,
                setInitialDate,
                setFinalDate,
                setRecurrentReservationType,
                setRecurrentReservationsSchedules,
                setDayOfWeekPreference,
                setHourPreference,
                setBarberPreference,
                setNonRegistredClient,

                resetValuesOfContext
            }}
        >
            {children}
        </RecurrentReservationContext.Provider>
    );
}
