import React, { useContext } from "react"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import { BarberBarberShopType, BarberType } from "../../../../types/barber"
import { api } from "../../../../config/api"
import { useNavigate, useParams } from "react-router-dom"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import MediumText from "../../../../components/texts/MediumText"
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles"
import NormalText from "../../../../components/texts/NormalText"
import colors from "../../../../style/colors"
import { ConfirmationButton, InputValue } from "../../../DoningReservation/style"
import { BarbershopContext } from "../../../../context/babershop"
import { toast } from "react-toastify"
import { TypesAccess } from "../../../../types/barbershop"
import Dropdown from "../../../../components/DropDown"

export default function BarberBarberShop() {
    const { id_barber, id_barbearia } = useParams()
    
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [barber, setBarber] = React.useState<BarberType>({
        id: 0,
        nome: "",
        telefone: "",
        email: "",
        cpf: "",
        barbearia_usuario_id: 0
    })
    const [barberBarberShop, setBarberBarberShop] = React.useState<BarberBarberShopType>({
        id: 0,
        usuario_id: 0,
        barbearia_id: 0,
        tipo_acesso_barbearia_id: 0,
        data_criacao: ""
    })
    const [typesAccess, setTypesAccess] = React.useState<TypesAccess>([])
    const [selectedTypeAccess, setSelectedTypeAccess] = React.useState<number>(0)

    const navigate = useNavigate()

    React.useEffect(() => {
        const getBarber = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/barbeiros/${id_barber}/barbearia/${id_barbearia}`)
                setBarber(response.data.usuario)
                setBarberBarberShop(response.data.usuario_barbearia)
                setSelectedTypeAccess(response.data.usuario_barbearia.tipo_acesso_barbearia_id)
            } catch (error) {
            } finally {
                hideLoadingWithDelay()
            }
        }

        const getTypesAccess = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/tipos_acesso`)
                setTypesAccess(response.data)
            } catch (error) {
            } finally {
                hideLoadingWithDelay()
            }
        }

        getBarber()
        getTypesAccess()
    }, [id_barber, id_barbearia])

    const handleInativate = async () => {
        try {
            setIsLoading(true)
            await api.put(`/barbeiros/tipo_acesso/`, {
                id_usuario: id_barber,
                id_barbearia: id_barbearia,
                id_tipo_acesso: selectedTypeAccess
            })
            toast.success("Tipo de acesso alterado com sucesso!")
            navigate(-1)
        } catch (error) {
        } finally {
            hideLoadingWithDelay()
        }
    }

    return (
        <>  
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Barbeiro</MediumText>

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>ID</NormalText>
                <InputValue
                    type="text"
                    value={barber?.id}
                    disabled
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                <InputValue
                    type="text"
                    value={barber?.nome}
                    disabled
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Email</NormalText>
                <InputValue
                    type="text"
                    value={barber?.email}
                    disabled
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                <InputValue
                    type="text"
                    value={barber?.telefone}
                    disabled
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>CPF</NormalText>
                <InputValue
                    type="text"
                    value={barber?.cpf}
                    disabled
                />
            </LabelAndValueContainer>

            {typesAccess?.length > 0 && (
                <Dropdown
                    options={typesAccess.map(type => ({ label: type.nome, value: type.id.toString() }))}
                    selectedOption={{ label: typesAccess.find(type => type.id === selectedTypeAccess)?.nome || "", value: selectedTypeAccess.toString() }}
                    setSelectedOption={(option) => {
                        setSelectedTypeAccess(parseInt(option.value))
                    }}
                    // label="Tipo de acesso"
                    // options={typesAccess.map(type => type.nome)}
                    // selectedOption={typesAccess.find(type => type.id === selectedTypeAccess)?.nome || ""}
                    // setSelectedOption={(option) => {
                    //     const type = typesAccess.find(type => type.nome === option)
                    //     setSelectedTypeAccess(type?.id || 0)
                    // }}
                />
            )} 
            
            <ConfirmationButton
                onClick={handleInativate}
            >
                <MediumText color="white" bold>Salvar tipo de acesso</MediumText>
            </ConfirmationButton>
            <GoBackButton
                onClick={() => navigate(-1)}
            >
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>


        </>
    )
}