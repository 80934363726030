import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BarbeariaType } from "../../../../types/barbershop"
import { api } from "../../../../config/api"
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles"
import colors from "../../../../style/colors"
import NormalText from "../../../../components/texts/NormalText"
import { ConfirmationButton, InputValue } from "../../../DoningReservation/style"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import { ServiceType } from "../../../../types/services"
import { toast } from "react-toastify"
import { AuthContext } from "../../../../context/authContext"
import { BarbershopContext } from "../../../../context/babershop"
import ButtonWithModal from "../../../../components/ButtonWithModal"

export default function Service() {
    const { id_service } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    // const { user } = React.useContext(AuthContext)
    const { barbershop } = React.useContext(BarbershopContext)
    const navigate = useNavigate()
    const [service, setService] = React.useState<ServiceType>({
        id: 0,
        tempo_estimado: 0,
        barbearia_id: 0,
        descricao: "",
        nome: "",
        preco: 0,
        data_criacao: ""
    }
    )

    React.useEffect(() => {
        const getBarberShop = async () => {
            const response = await api.get(`/servicos/${id_service}`)
            setService(response.data)
        }
        if (id_service) {
            try {
                setIsLoading(true)
                getBarberShop()
            } catch (error) {
                toast.error("Erro ao criar Servico")
            } finally {
                hideLoadingWithDelay()
            }
        }
    }, [id_service])

    const handleConfirm = async () => {
        setIsLoading(true)
        if (id_service) {
            // update
            try {
                const response = await api.put('/servicos/', service)
                if (response.status === 200 || response.data.id) {
                    toast.success("Servico atualizado com sucesso")
                    navigate(-1)
                } else {
                    toast.error("Erro ao criar Servico")
                }
            }
            catch (error) {
                toast.error("Erro ao criar Servico")
            }

        } else {
            try {
                const create_service = {
                    descricao: service.descricao,
                    nome: service.nome,
                    preco: service.preco,
                    tempo_estimado: service.tempo_estimado,
                    barbearia_id: barbershop?.id
                }
                const response = await api.post('/servicos/', create_service)
                if (response.status === 201 || response.data.id) {
                    toast.success("Servico criado com sucesso")
                    navigate(-1)
                } else {
                    toast.error("Erro ao criar Servico")
                }
            }
            catch (error) {
                toast.error("Erro ao criar Servico")
            }
        }
        hideLoadingWithDelay()
    }

    const handleDelete = async () => {
        setIsLoading(true)
        try {
            const response = await api.delete(`/servicos/${service.id}`)
            if (response.status === 200) {
                toast.success("Servico deletado com sucesso")
                navigate(-1)
            } else {
                toast.error("Erro ao deletar Servico")
            }
        }
        catch (error) {
            toast.error("Erro ao deletar Servico")
        }
        hideLoadingWithDelay()
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <NormalText bold>Serviço</NormalText>
            {service?.id === 0 ?
                <NormalText bold>Novo Serviço</NormalText>
                :
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>ID</NormalText>
                    <InputValue
                        type="text"
                        value={service?.id}
                        disabled
                    />
                </LabelAndValueContainer>
            }

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                <InputValue
                    type="text"
                    value={service?.nome}
                    onChange={(e) => {
                        setService({
                            ...service,
                            nome: e.target.value
                        })
                    }}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Descrição</NormalText>
                <InputValue
                    type="text"
                    value={service?.descricao}
                    onChange={(e) => {
                        setService({
                            ...service,
                            descricao: e.target.value
                        })
                    }}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Preço</NormalText>
                <InputValue
                    type="text"
                    value={service?.preco}
                    onChange={(e) => {
                        setService({
                            ...service,
                            preco: Number(e.target.value)
                        })
                    }}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Tempo estimado (Minutos)</NormalText>
                <InputValue
                    type="text"
                    value={service?.tempo_estimado}
                    onChange={(e) => {
                        setService({
                            ...service,
                            tempo_estimado: Number(e.target.value)
                        })
                    }}
                />
            </LabelAndValueContainer>
            <ConfirmationButton
                onClick={handleConfirm}
            >
                <NormalText color="white" bold>Salvar</NormalText>
            </ConfirmationButton>
            {service?.id !== 0 &&
                <ButtonWithModal
                    label="Deletar Serviço"
                    title="Deseja realmente deletar o serviço?"
                    cancelFunction={handleDelete} 
                />
            }
            {/* < ButtonWithModal
                label="Deletar Serviço"
                title="Deseja realmente deletar o serviço?"
                cancelFunction={handleDelete}
            /> */}

            <GoBackButton
                // onClick={() => navigate('/barber/ausencias')}
                onClick={() => navigate(-1)}
            >
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>

        </>
    )
}