import React from "react";
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen";
import MediumText from "../../../components/texts/MediumText";
import { LoadingHook } from "../../../hooks/LoadingHook";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { BarbeariasType } from "../../../types/barbershop";
import { api } from "../../../config/api";
import { ResumeContainer } from "../../DoningReservation/style";
import { LabelAndValueContainer } from "../../globalStyles";
import SmallText from "../../../components/texts/SmallText";
import colors from "../../../style/colors";
import NormalText from "../../../components/texts/NormalText";

export default function Barbershops() {
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const { user } = React.useContext(AuthContext);
    

    const [barbershops, setBarbershops] = React.useState<BarbeariasType>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        setIsLoading(true)

        const getBarbershops = async () => {
            try {
                const response = await api.get(`/barbearias/user/${user?.id}`)
                setBarbershops(response.data)
            } catch (error) {
            } finally {
                hideLoadingWithDelay()
            }
        }

        getBarbershops()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Barbearias</MediumText>
            
            {barbershops.map(barbershop => (
                <ResumeContainer key={barbershop.id} onClick={() => navigate(`barbearia/${barbershop.id}`)}>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Nome</SmallText>
                        <NormalText color={colors.mediumGray}>{barbershop.nome}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Endereço</SmallText>
                        <NormalText color={colors.mediumGray}>{barbershop.endereco}</NormalText>
                    </LabelAndValueContainer>
                </ResumeContainer>
            ))}
        </>
    )
}