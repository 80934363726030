import React, { useContext, useEffect } from "react";
import { GoBackButton, LabelAndValueContainer } from "../globalStyles";
import NormalText from "../../components/texts/NormalText";
import colors from "../../style/colors";
import SmallText from "../../components/texts/SmallText";
import MediumText from "../../components/texts/MediumText";
import { useNavigate } from "react-router-dom";
import { InprogressReservationContext } from "../../context/inProgressReservation";
import { BarbershopContext } from "../../context/babershop";
import { composeDateAndHour, formatDateToDDMMYYYY } from "../../functions/date";
import { ConfirmationButton, InputValue, ResumeContainer } from "./style";
import { LoadingHook } from "../../hooks/LoadingHook";
import LoadingFreezeScreen from "../../components/LoadingFreezeScreen";
import { toast } from "react-toastify";
import { api } from "../../config/api";

export default function DoningReservation() {
    const navigate = useNavigate()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const inprogressReservationContext = useContext(InprogressReservationContext)
    const {
        selectedBaber,
        selectedDate,
        selectedHour,
        selectedServices,
        total,
        nonRegistredClient,
        setNonRegistredClient,
        resetValuesOfContext
    } = inprogressReservationContext

    useEffect(() => {
        setIsLoading(true)
        hideLoadingWithDelay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleConfirmReservation = async () => {
        setIsLoading(true)

        if (!nonRegistredClient.nome || !nonRegistredClient.telefone) {
            toast.error("Preencha seu nome e telefone por favor")
            hideLoadingWithDelay()
            return
        }

        // const phoneRegex = /^\d{2}\d{5}\d{4}$/
        // validate phone number
        const phoneRegex = /^\d{2}\d{4,5}\d{4}$/
        if (!phoneRegex.test(nonRegistredClient.telefone)) {
            toast.error("Telefone inválido")
            hideLoadingWithDelay()
            return
        }

        if (!selectedBaber || !selectedDate || !selectedHour || selectedServices.length === 0) {
            toast.error("Erro na obtenção de campos")
            hideLoadingWithDelay()
            return
        }

        const object_values = {
            agendamento: {
                barbearia_usuario_id: selectedBaber[0]?.id,
                data_agendamento: composeDateAndHour(selectedDate, selectedHour)
            },
            cliente_nao_cadastrado: {
                nome: nonRegistredClient.nome,
                telefone: nonRegistredClient.telefone
            },
            agendamento_servicos: selectedServices.map(service => ({
                servico_id: service.id,
                valor: service.preco,
                tempo_estimado: service.tempo_estimado
            }))
        }

        try {
            const response = await api.post('/agendamentos_completos/', object_values)
            const agendamento = response.data.agendamento
            toast.success("Reserva realizada com sucesso")
            resetValuesOfContext()
            navigate(`/reserva/concluida/${agendamento.id}`)
        } catch (error: any) {
            if (error?.response?.data?.detail) {
                toast.error(error.response.data.detail)
            } else {
                toast.error("Erro ao realizar reserva")
            }
            hideLoadingWithDelay()
        }
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <NormalText bold>Finalize sua reserva</NormalText>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                <InputValue
                    type="text"
                    placeholder="Seu nome aqui"
                    value={nonRegistredClient.nome}
                    onChange={(e) => setNonRegistredClient({
                        ...nonRegistredClient,
                        nome: e.target.value
                    })}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                <InputValue
                    type="tel"
                    placeholder="1799999-9999"
                    value={nonRegistredClient.telefone}
                    onChange={(e) => setNonRegistredClient({
                        ...nonRegistredClient,
                        telefone: e.target.value
                    })}
                />
            </LabelAndValueContainer>

            <ResumeContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Barbeiro</SmallText>
                    <NormalText color={colors.mediumGray}>{selectedBaber[0]?.nome}</NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Endereço</SmallText>
                    <NormalText color={colors.mediumGray}>{barbershop?.endereco}</NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Serviços</SmallText>
                    {selectedServices.map(service => (
                        <NormalText color={colors.mediumGray} key={service.id}>- {service.nome}</NormalText>
                    ))}
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Data hora</SmallText>
                    <NormalText color={colors.mediumGray}>{formatDateToDDMMYYYY(selectedDate)} - {selectedHour}</NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Total</NormalText>
                    <MediumText color={colors.mediumGray} bold>R$ {total.total}</MediumText>
                </LabelAndValueContainer>
            </ResumeContainer>

            <ConfirmationButton
                onClick={handleConfirmReservation}
            >
                <MediumText color="white" bold>Confirmar reserva</MediumText>
            </ConfirmationButton>
            <GoBackButton
                // onClick={() => navigate('/reserva')}
                onClick={() => navigate(-1)}
            >
                <MediumText bold>Voltar</MediumText>
            </GoBackButton>
        </>
    );
}
