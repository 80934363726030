import styled from "styled-components";
import colors from "../../style/colors";

export const DropdownContainer = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 10px;
    padding: 20px 20px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    user-select: none;
`

export const DropdownLabel = styled.span`
    font-size: 14px;
    color: ${colors.darkGray};
`

export const RelativeDropdownContainer = styled.div`
    position: relative;
`

export const DropdownOptionsContainer = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    background-color: ${colors.white};	
    border-radius: 5px;
    z-index: 1;
    border: 1px solid ${colors.lightGray};
`

export const DropdownOption = styled.div`
    padding: 15px;
    font-size: 14px;
    color: ${colors.darkGray};
    cursor: pointer;

    &:hover {
        background-color: ${colors.lightGray};
    }
`

