import React, { useContext, useEffect, useState } from "react"
import { BarbershopContext } from "../../context/babershop"
import { useNavigate } from "react-router-dom"
import { BarbersType, BarberType } from "../../types/barber"
import { UsuarioFotoType } from "../../types/user"
import { LoadingHook } from "../../hooks/LoadingHook"
import { api } from "../../config/api"
import { LabelAndValueContainer } from "../../pages/globalStyles"
import NormalText from "../texts/NormalText"
import colors from "../../style/colors"
import { BarberImage, BarberImageAndNameContainer, BarberName, BarbersContainer } from "../../pages/InProgressReservation/style"
import { FaRegUser } from "react-icons/fa"
import LoadingFreezeScreen from "../LoadingFreezeScreen"

type BarberSelectorProps = {
    selectedBarbers: BarbersType;
    setSelectedBarbers: (barber: BarbersType) => void;
    onlyOneBarber?: boolean;
}

export default function BarberSelector({ selectedBarbers, setSelectedBarbers, onlyOneBarber = true }: BarberSelectorProps) {
    const navigate = useNavigate()
    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const [barbers, setBarbers] = useState<BarbersType>([]);
    const [barbersPhotos, setBarbersPhotos] = useState<UsuarioFotoType[]>([])

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    useEffect(() => {
        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${barbershop?.id}`)
                setBarbers(response.data)
                // if (selectedBarbers.length === 0)
                // setSelectedBarbers([response.data[0]])
            } catch (error) {
            }
        }

        const getBarbersPhotos = async () => {
            try {
                const response = await api.get(`/usuarios_foto/barbearia/${barbershop?.id}`)
                setBarbersPhotos(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getBarbers()
        getBarbersPhotos()
        hideLoadingWithDelay()
    }, [barbershop?.id])

    const handleSelectedBarber = (barber: BarberType) => {
        let updatedBarbers;

        if (onlyOneBarber) {
            updatedBarbers = [barber];
        } else {
            if (selectedBarbers.find((selectedBarber: BarberType) => selectedBarber.id === barber.id)) {
                updatedBarbers = selectedBarbers.filter((selectedBarber: BarberType) => selectedBarber.id !== barber.id);
            } else {
                updatedBarbers = [...selectedBarbers, barber];
            }
        }

        // Sort the updated barbers by id in ascending order
        updatedBarbers = updatedBarbers.slice().sort((a, b) => a.id - b.id);

        setSelectedBarbers(updatedBarbers);
    }


    const isSelected = (barber: BarberType) => {
        return selectedBarbers.find((selectedBarber: BarberType) => selectedBarber.id === barber.id) !== undefined
    }

    type BarberCardProps = {
        barber: BarberType;
        barberPhoto?: UsuarioFotoType;
        index: number;
        isSelected: (barber: BarberType) => boolean;
        handleSelectedBarber: (barber: BarberType) => void;
    };

    const BarberCard = ({ barber, barberPhoto, index, isSelected, handleSelectedBarber }: BarberCardProps) => (
        <BarberImageAndNameContainer
            key={index}
            onClick={() => handleSelectedBarber(barber)}
            selected={isSelected(barber)}
        >
            {barberPhoto?.foto ? (
                <BarberImage src={process.env.REACT_APP_API_URL + barberPhoto.foto} alt="Barber Image" />
            ) : (
                <FaRegUser color={colors.mediumGray} size={20} />
            )}
            <BarberName color={isSelected(barber) ? colors.white : colors.mediumGray}>
                {barber.nome}
            </BarberName>
        </BarberImageAndNameContainer>
    );


    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <LabelAndValueContainer>
                <NormalText color={colors.darkGray} bold>Escolha seu barbeiro</NormalText>
                <BarbersContainer>
                    {barbers.map((barber: BarberType, index: number) => {
                        const barberPhoto = barbersPhotos.find((barberPhoto: UsuarioFotoType) => barberPhoto.usuario_id === barber.id);
                        return (
                            <BarberCard
                                key={index}
                                barber={barber}
                                barberPhoto={barberPhoto}
                                index={index}
                                isSelected={isSelected}
                                handleSelectedBarber={handleSelectedBarber}
                            />
                        );
                    })}
                </BarbersContainer>
            </LabelAndValueContainer>
        </>
    )
}

