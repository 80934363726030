import React, { useEffect } from "react";
import { api } from "../config/api";
import { BarberShopPhotoType } from "../types/barbershop";

export type Barbershop = {
    endereco: string;
    id: number;
    telefone: string;
    data_criacao: string;
    nome: string;
};

type BarbershopContextType = {
    barbershop: Barbershop | null;
    setBarbershop: (barbershop: Barbershop) => void;
    barberShopPhoto: BarberShopPhotoType | null;
    setBarberShopPhoto: (barberShopPhoto: BarberShopPhotoType) => void;
};

export const BarbershopContext = React.createContext<BarbershopContextType>({
    barbershop: null,
    setBarbershop: () => { },
    barberShopPhoto: null,
    setBarberShopPhoto: () => { }
});

export function BarbershopProvider({ children }: { children: React.ReactNode }) {
    const [barbershop, setBarbershop] = React.useState<Barbershop | null>(null);
    const [barberShopPhoto, setBarberShopPhoto] = React.useState<BarberShopPhotoType>({
        id: 0,
        barbearia_id: 0,
        foto: '',
        data_criacao: ''
    })

    useEffect(() => {
        const getBarberShop = async () => {
            try {
                const response = await api.get("/barbearias/1")
                // setBarbershop(response.data)
                if (response.data) {
                    setBarbershop(response.data)
                    const responsePhoto = await api.get(`/barbearias_foto/barbearia/${1}`)
                    if (responsePhoto.data) {
                        setBarberShopPhoto(responsePhoto.data)
                    }
                }
            } catch (error) {
            }
        }

        getBarberShop()
    }, [setBarbershop])

    return (
        <BarbershopContext.Provider value={{
            barbershop,
            setBarbershop,
            barberShopPhoto,
            setBarberShopPhoto
        }}>
            {children}
        </BarbershopContext.Provider>
    );
};