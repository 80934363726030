import React, { useContext, useEffect } from "react"
import colors from "../../style/colors"
import { FaBars, FaRegCalendarAlt, FaUserAlt, FaWhatsapp, FaStoreAlt, FaUser, FaTag, FaStore } from 'react-icons/fa';
import { BackgroundMenuContainer, Container, LogoNameContainer, LogoNameFullContainer, MenuContainer, MenuIconContainer, MenuItem, MenuItemContainer } from "./style";
import { useNavigate } from "react-router-dom";
import { BarbershopContext } from "../../context/babershop";
import MediumText from "../texts/MediumText";
import { AuthContext } from "../../context/authContext";
import { handleGoToLocation, handleGoToWhatsAppChat } from "../../pages/Home/UnlogedHome";

export default function Topbar() {
    const [menuOpen, setMenuOpen] = React.useState(false)
    const navigate = useNavigate()
    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [menuOpen]);

    const handleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const handleNavigate = (path: string) => {
        navigate(path)
        setMenuOpen(false)
    }

    const handleProfile = () => {
        if (user?.id) 
            return navigate('/profile')
        navigate('/login')
    }

    return (
        <>
            <Container>
                <MenuIconContainer onClick={handleMenu}>
                    <FaBars color={colors.mediumGray} size={20} />
                </MenuIconContainer>
                <LogoNameFullContainer>
                    <LogoNameContainer onClick={() => handleNavigate('/')}>
                        <MediumText bold>{barbershop?.nome}</MediumText>
                    </LogoNameContainer>
                </LogoNameFullContainer>
                <MenuIconContainer onClick={handleProfile}>
                    <FaUser color={colors.mediumGray} size={20} />
                </MenuIconContainer>
            </Container>
            {(menuOpen && !user?.id) && (
                <>
                    <BackgroundMenuContainer onClick={() => setMenuOpen(false)} />
                    <MenuContainer open={menuOpen}>
                        <MenuItemContainer onClick={() => handleNavigate('/reserva')}>
                            <FaRegCalendarAlt color={colors.mediumGray} size={20} />
                            <MenuItem>Faça sua reserva</MenuItem>
                        </MenuItemContainer>
                        <MenuItemContainer onClick={() => handleNavigate('reserva/buscar')}>
                            <FaUserAlt color={colors.mediumGray} size={20} />
                            <MenuItem>Veja sua reserva</MenuItem>
                        </MenuItemContainer>
                        <MenuItemContainer  onClick={handleGoToWhatsAppChat}>
                            <FaWhatsapp color={colors.mediumGray} size={20}/>
                            <MenuItem>Entre em contato</MenuItem>
                        </MenuItemContainer>
                        <MenuItemContainer onClick={handleGoToLocation}>
                            <FaStoreAlt color={colors.mediumGray} size={20}/>
                            <MenuItem>Ver localização</MenuItem>
                        </MenuItemContainer>
                    </MenuContainer>
                </>
            )}
             {(menuOpen && user?.id) && (
                <>
                    <BackgroundMenuContainer onClick={() => setMenuOpen(false)} />
                    <MenuContainer open={menuOpen}>
                        <MenuItemContainer onClick={() => handleNavigate('/barber/reservas')}>
                            <FaRegCalendarAlt color={colors.mediumGray} size={20} />
                            <MenuItem>Agendas</MenuItem>
                        </MenuItemContainer>
                        <MenuItemContainer onClick={() => handleNavigate('/barber/ausencias')}>
                            <FaTag color={colors.mediumGray} size={20} />
                            <MenuItem>Ausencias</MenuItem>
                        </MenuItemContainer>
                        <MenuItemContainer onClick={() => handleNavigate('/barbearias')}>
                            <FaStore color={colors.mediumGray} size={20} />
                            <MenuItem>Barbearias</MenuItem>
                        </MenuItemContainer>
                    </MenuContainer>
                </>
            )}
        </>
    )
}