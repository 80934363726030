import React, { useEffect } from 'react'
import styled from 'styled-components'
import LoadingFreezeScreen from '../../../../components/LoadingFreezeScreen'
import MediumText from '../../../../components/texts/MediumText'
import { LoadingHook } from '../../../../hooks/LoadingHook'
import GenericButton from '../../../../components/GenericButton'
import { AusencesType } from '../../../../types/ausences'
import colors from '../../../../style/colors'
import { ResumeContainer } from '../../../DoningReservation/style'
import { GoBackButton, LabelAndValueContainer } from '../../../globalStyles'
import SmallText from '../../../../components/texts/SmallText'
import NormalText from '../../../../components/texts/NormalText'
import { formatDateToDDMMYYYY, formatDateToDDMMYYYYFromString, getDateFromDateTime } from '../../../../functions/date'
import { formatHourToHHMM } from '../../../../functions/hour'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../../config/api'
import { toast } from 'react-toastify'
import { AuthContext } from '../../../../context/authContext'
import { BarbershopContext } from '../../../../context/babershop'

const AusencesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Ausence = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
`

// const mock_ausences = [
//     {
//         id: 1,
//         descricao: "Teste",
//         data_inicio: "2021-09-30",
//         data_fim: "2021-09-30",
//         hora_inicio: "23:57:46.517000",
//         hora_fim: "23:59:46.517000",
//         recorrente: false,
//         barbearia_usuario_id: 1,
//         // data_hora_inicio: "2021-09-30T12:00:00",
//         data_criacao: "2021-09-30T12:00:00",
//         ativa: true
//     }    
// ]

export default function ListAusences() {
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [ausences, setAusences] = React.useState<AusencesType>([])
    const { user } = React.useContext(AuthContext)    
    const { barbershop } = React.useContext(BarbershopContext)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchAusences = async () => {
            // const response = await api.get('/ausencias/')
            if (!barbershop || !user) {
                return navigate('/')
            }
            const response = await api.get(`/ausencias/barbearia_usuario/${barbershop?.id}/${user?.id}`)
            if (response) {
                setAusences(response.data)
            }
        }

        try {
            setIsLoading(true)
            fetchAusences()
        } catch {
            toast.error('Falha ao obter ausencias')
        } finally {
            hideLoadingWithDelay()
        }
    }, [])



    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Ausencias</MediumText>
            <GenericButton onClick={() => navigate('/barber/ausencias/ausencia/')}>
                Adicionar nova ausencia
            </GenericButton>
            <AusencesContainer>
                {ausences.map((ausence, index) => (
                    // <Ausence key={index}>
                    //     <MediumText bold>Data de inicio: {ausence.data_hora_inicio}</MediumText>
                    //     <MediumText bold>Data de fim: {ausence.data_hora_fim}</MediumText>
                    //     <MediumText bold>Recorrente: {ausence.recorrente ? "Sim" : "Não"}</MediumText>
                    //     <MediumText bold>Ativa: {ausence.ativa ? "Sim" : "Não"}</MediumText>
                    // </Ausence>
                    <ResumeContainer onClick={() => navigate(`/barber/ausencias/ausencia/${ausence.id}`)}>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Descricao</SmallText>
                            <NormalText color={colors.mediumGray}>{ausence.descricao}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Data de inicio</SmallText>
                            <NormalText color={colors.mediumGray}>{formatDateToDDMMYYYYFromString(ausence.data_inicio)}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Recorrente</SmallText>
                            <NormalText color={colors.mediumGray}>{ausence.recorrente ? "Sim" : "Não"}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Data de fim</SmallText>
                            <NormalText color={colors.mediumGray}>{
                                ausence.recorrente ? "Recorrente" :
                                    formatDateToDDMMYYYYFromString(ausence.data_fim ?? '')
                            }</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Ativa</SmallText>
                            <NormalText color={colors.mediumGray}>{ausence.ativa ? "Sim" : "Não"}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Hora inico - fim</SmallText>
                            <NormalText color={colors.mediumGray}>{formatHourToHHMM(ausence.hora_inicio)} - {formatHourToHHMM(ausence.hora_fim)}</NormalText>
                        </LabelAndValueContainer>
                    </ResumeContainer>
                ))}
            </AusencesContainer>
            <GoBackButton
                // onClick={() => navigate('/barber/ausencias')}
                onClick={() => navigate(-1)}
            > <NormalText bold>Voltar</NormalText>
            </GoBackButton>
        </>
    )
}
