import React, { useContext, useEffect } from "react";
import { GoBackButton, LabelAndValueContainer } from "../globalStyles";
import NormalText from "../../components/texts/NormalText";
import colors from "../../style/colors";
import SmallText from "../../components/texts/SmallText";
import MediumText from "../../components/texts/MediumText";
import { FaCalendarCheck, FaWhatsapp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { InputValue, ResumeContainer } from "../DoningReservation/style";
import { DonedReservationContainer, InProgressReservationContainer, WhatsAppButton } from "./style";
import { BarbershopContext } from "../../context/babershop";
import { LoadingHook } from "../../hooks/LoadingHook";
import { ReservationType } from "../../types/reservation";
import { api } from "../../config/api";
import LoadingFreezeScreen from "../../components/LoadingFreezeScreen";
import { getDateFromDateTime, getHourFromDateTime } from "../../functions/date";
import { AuthContext } from "../../context/authContext";
import ButtonWithModal from "../../components/ButtonWithModal";

export default function DonedReservation() {
    const navigate = useNavigate()

    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext

    const { id } = useParams<{ id: string }>();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [reservation, setReservation] = React.useState<ReservationType>()
    const { user } = React.useContext(AuthContext);

    useEffect(() => {
        setIsLoading(true)

        const getReservation = async () => {
            try {
                const response = await api.get(`/agendamentos/${id}`)
                setReservation(response.data)
            } catch (error) {
            }
        }

        getReservation()
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleGoToHome = () => {
        navigate('/')
    }

    const handleGoBack = () => {
        navigate('/barber/reservas')
    }

    const handleGoToWhatsAppChat = () => {
        if (!reservation) return
        const message = `Olá, gostaria de informar que tenho uma reserva para o dia ${getDateFromDateTime(reservation.agendamento.data_agendamento)} às ${getHourFromDateTime(reservation.agendamento.data_agendamento)} com os serviços: ${reservation.servicos.map(service => service.nome).join(', ')} no valor de R$ ${reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}.`
        const url = `https://api.whatsapp.com/send?phone=55${barbershop?.telefone}&text=${message}`;
        window.open(url, "_blank")
    }

    const handleGoToWhatsAppClientChat = () => {
        if (!reservation) return

        const message = `Olá, gostaria de conversar sobre a reserva para o dia ${getDateFromDateTime(reservation.agendamento.data_agendamento)} às ${getHourFromDateTime(reservation.agendamento.data_agendamento)} para os serviços: ${reservation.servicos.map(service => service.nome).join(', ')} no valor de R$ ${reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}.`
        const url = `https://api.whatsapp.com/send?phone=55${reservation.cliente_nao_cadastrado.telefone}&text=${message}`;
        window.open(url, "_blank")
    }

    const handleChangeStatusAgendamento = (status_id: number) => {
        setIsLoading(true)
        try {
            api.put(`/agendamentos/${id}`, { status_agendamento_id: status_id })
                .then(response => {
                    const agendamento = response.data
                    if (reservation) {
                        setReservation({ ...reservation, agendamento: { ...reservation.agendamento, status_agendamento_id: agendamento.status_agendamento_id } });
                    }
                })
        } catch (error) {
        }
        hideLoadingWithDelay()
    }

    return (
        <>
            {isLoading && <LoadingFreezeScreen isLoading />}
            {!reservation && <NormalText>Carregando...</NormalText>}

            {reservation && (
                <>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                        <InputValue
                            type="text"
                            value={reservation.cliente_nao_cadastrado.nome}
                            disabled
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                        <InputValue
                            type="number"
                            value={reservation.cliente_nao_cadastrado.telefone}
                            disabled
                        />
                    </LabelAndValueContainer>

                    <ResumeContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Barbeiro</SmallText>
                            <NormalText color={colors.mediumGray}>{reservation.usuario.nome}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Endereço</SmallText>
                            <NormalText color={colors.mediumGray}>{barbershop?.endereco}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Serviços</SmallText>
                            {reservation.servicos.map(service => (
                                <NormalText color={colors.mediumGray} key={service.id}>- {service.nome}</NormalText>
                            ))}
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Data</SmallText>
                            <NormalText color={colors.mediumGray}>{getDateFromDateTime(reservation.agendamento.data_agendamento)}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Total</SmallText>
                            <NormalText color={colors.mediumGray} bold>R$ {reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Hora</SmallText>
                            <NormalText color={colors.mediumGray}>{getHourFromDateTime(reservation.agendamento.data_agendamento)}</NormalText>
                        </LabelAndValueContainer>
                    </ResumeContainer>

                    {user?.id ? (
                        <>
                            {reservation.agendamento.status_agendamento_id === 4 ? (
                                <MediumText color={colors.red} bold>Reserva cancelada</MediumText>
                            ) : 
                            <>
                                {reservation.agendamento.status_agendamento_id === 3 ? (
                                    <DonedReservationContainer onClick={() => handleChangeStatusAgendamento(1)}>
                                        <FaCalendarCheck size={15} color={colors.white} />
                                        <NormalText color={colors.white} bold>Reserva concluída</NormalText>
                                    </DonedReservationContainer>
                                ) :
                                    (<InProgressReservationContainer onClick={() => handleChangeStatusAgendamento(3)}>
                                        <FaCalendarCheck size={15} color={colors.mediumGray} />
                                        <NormalText color={colors.mediumGray} bold>Concluir reserva</NormalText>
                                    </InProgressReservationContainer>
                                    )
                                }
                                < ButtonWithModal
                                    label="Cancelar reserva"
                                    title="Deseja realmente cancelar a reserva?"
                                    cancelFunction={() => handleChangeStatusAgendamento(4)}
                                />
                            </>
                            }

                            <WhatsAppButton onClick={handleGoToWhatsAppClientChat}>
                                <FaWhatsapp size={30} />
                                <NormalText color="white" bold>Entrar em contato com o cliente</NormalText>
                            </WhatsAppButton>
                            <GoBackButton
                                onClick={handleGoBack}
                            >
                                <MediumText bold color={colors.mediumGray}>Voltar</MediumText>
                            </GoBackButton>
                        </>
                    ) : (
                        <>
                            <WhatsAppButton onClick={handleGoToWhatsAppChat}>
                                <FaWhatsapp size={30} />
                                <NormalText color="white" bold>Entrar em contato com o barbeiro</NormalText>
                            </WhatsAppButton>
                            <GoBackButton
                                onClick={handleGoToHome}
                            >
                                <MediumText bold color={colors.mediumGray}>Voltar</MediumText>
                            </GoBackButton>
                        </>
                    )
                    }
                </>
            )
            }
        </>
    );
}
