import React from "react";
import { useNavigate } from "react-router-dom";
import { GoBackButton, LabelAndValueContainer } from "../globalStyles";
import NormalText from "../../components/texts/NormalText";
import { ConfirmationButton, InputValue, ResumeContainer } from "../DoningReservation/style";
import colors from "../../style/colors";
import MediumText from "../../components/texts/MediumText";
import { api } from "../../config/api";
import LoadingFreezeScreen from "../../components/LoadingFreezeScreen";
import { LoadingHook } from "../../hooks/LoadingHook";
import { GetReservationsType } from "../../types/reservation";
import SmallText from "../../components/texts/SmallText";
import { getDateFromDateTime, getHourFromDateTime } from "../../functions/date";

export default function GetReservation() {
    const [phoneInput, setPhoneInput] = React.useState("")
    const navigate = useNavigate()
    const [reservations, setReservations] = React.useState<GetReservationsType>([])
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const handleSearchReservation = () => {
        setIsLoading(true)
        try {
            api.get(`/agendamentos_completos/${phoneInput}`)
                .then(response => {
                    setReservations(response.data)
                })
        } catch (error) {
        }
        hideLoadingWithDelay()
    }

    const handleGoToReservation = (id: number) => {
        navigate(`/reserva/concluida/${id}`)
    }

    return <>
        <LoadingFreezeScreen isLoading={isLoading} />
        <LabelAndValueContainer>
            <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
            <InputValue
                type="tel"
                placeholder="17999999999"
                value={phoneInput}
                onChange={(e) => setPhoneInput(e.target.value)}
            />
        </LabelAndValueContainer>

        {reservations.map(reservation => (
            <ResumeContainer key={reservation.agendamento.id} onClick={() => handleGoToReservation(reservation.agendamento.id)}>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Barbeiro</SmallText>
                    <NormalText color={colors.mediumGray}>{reservation.usuario.nome}</NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Endereço</SmallText>
                    <NormalText color={colors.mediumGray}>{reservation.barbearia.endereco}</NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Serviços</SmallText>
                    {reservation.servicos.map(service => (
                        <NormalText color={colors.mediumGray} key={service.id}>- {service.nome}</NormalText>
                    ))}
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Data hora</SmallText>
                    <NormalText color={colors.mediumGray}>{getDateFromDateTime(reservation.agendamento.data_agendamento)}</NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Total</NormalText>
                    <NormalText color={colors.mediumGray}>R$ {reservation.agendamento_servicos.reduce((acc, curr) => { return acc + curr.valor }, 0)}
                    </NormalText>
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <SmallText color={colors.mediumGray} bold>Hora</SmallText>
                    <NormalText color={colors.mediumGray}>{getHourFromDateTime(reservation.agendamento.data_agendamento)}</NormalText>
                </LabelAndValueContainer>
            </ResumeContainer>
        ))}

        <ConfirmationButton
            onClick={handleSearchReservation}
        >
            <MediumText color="white" bold>Buscar reservas</MediumText>
        </ConfirmationButton>
        <GoBackButton
            // onClick={() => navigate('/reserva')}
            onClick={() => navigate(-1)}
        >
            <MediumText bold>Voltar</MediumText>
        </GoBackButton>
    </>
}