import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediumText from "../../../components/texts/MediumText";
// import Calendar from "../../../components/Calendar";
import { useNavigate } from "react-router-dom";
import colors from "../../../style/colors";
import NormalText from "../../../components/texts/NormalText";
import { formatDateToDDMMYYYY, formatDateToYYYYMMDD, getHourFromDateTime } from "../../../functions/date";
import { FaCalendarCheck, FaFilter, FaPlus, FaSync } from "react-icons/fa";
import Calendar from "../../../components/Calendar";
import { LoadingHook } from "../../../hooks/LoadingHook";
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen";
import { GetReservationsType, ReservationAusencesBarbersListType, ReservationAusencesBarbersType, ReservationAusencesListType } from "../../../types/reservation";
import { api } from "../../../config/api";
import { AusencesType } from "../../../types/ausences";
import { AuthContext } from "../../../context/authContext";
import { formatHourToHHMM } from "../../../functions/hour";
import { LabelAndValueContainer } from "../../globalStyles";
import SmallText from "../../../components/texts/SmallText";
import GenericModal from "../../../components/GenericModal";
import BarberSelector from "../../../components/BarberSelector";
import { BarbersType, BarberType } from "../../../types/barber";
import DailyScheduleTable from "../../../components/Schedules";
import { BarbershopContext } from "../../../context/babershop";
import { set } from "date-fns";

const SelectedDate = styled.div`
    display: flex;
    align-items: center;
    padding: 13px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    gap: 10px;
    transition: 0.3s;
    outline: 2px solid transparent;
    width: 100%;

    &:hover {
        background-color: ${colors.gray};
        cursor: pointer;
    }

    &:active {
        outline: 2px solid ${colors.mediumGray};
    }
`;


const TwoButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
    /* flex-direction: column; */
`;

const NewReservationButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.darkGray};
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 10px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background-color: ${colors.mediumGray};
    }

    &:active {
        background-color: ${colors.darkGray};
        outline: 2px solid ${colors.gray};
    }
`;

const NewReservationButtonModal = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    border: 1px solid ${colors.gray};

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`;

const NewAusenceButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    border: 1px solid ${colors.gray};

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`;

export const ReservationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;


export const ReservationContainer = styled.div<{ status_agendamento_id?: number }>`
    display: flex;
    gap: 5px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: ${props => props.status_agendamento_id === 3 ? colors.gray : colors.white};
    /* background-color: ${props => props.status_agendamento_id === 4 ? colors.lightRed : colors.white}; */
    outline: 1px solid ${colors.lightGray};

    &:hover {
        background-color: ${colors.lightGray};
        cursor: pointer;
    }

    &:active {
        outline: 2px solid ${colors.gray};
        
    }
`;

export const AusenceContainer = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: ${colors.gray};

    &:hover {
        background-color: ${colors.lightGray};
        cursor: pointer;
    }

    &:active {
        outline: 2px solid ${colors.gray};
    }
`;

export const HourContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 10px;
    border-right: 2px solid ${colors.gray};
    min-width: 70px;
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0 10px;
    gap: 5px;
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* background-color: ${colors.lightGray}; */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${colors.lightGray};
`;

const SelectedAndUpdateButtonContainer = styled.div`
    display: flex;
    /* width: 100%; */
    gap: 5px;
`;

const UpdateButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    padding: 10px 40px;
    gap: 5px;
    transition: background-color 0.3s;
    user-select: none;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`;


export default function Schedules() {
    const navigate = useNavigate();
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    // const [barbersLoaded, setBarbersLoaded] = useState(false);

    const { user } = useContext(AuthContext)
    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const [selectedDate, setSelectedDate] = useState(() => {
        const date = new Date();
        const localDate = new Date(date.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
        return localDate;
    });
    const [showSelectDate, setShowSelectDate] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [reservationsAndAusences, setReservationsAndAusences] = React.useState<ReservationAusencesBarbersListType>([])
    const [isModalSchedulesOpen, setIsModalSchedulesOpen] = useState(false);
    const [isModalFiltersOpen, setIsModalFiltersOpen] = useState(false);
    const [selectedBarbers, setSelectedBarbers] = useState<BarbersType>([]);

    const handleSelectDate = () => {
        setShowSelectDate(!showSelectDate);
    }

    useEffect(() => {
        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${barbershop?.id}`)
                setSelectedBarbers(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getBarbers()
        hideLoadingWithDelay()
    }, [barbershop?.id])

    useEffect(() => {
        if (firstRender && selectedBarbers.length > 0) {
            setFirstRender(false)
            setIsLoading(true)
            try {
                const selectedDateFromLocalStorage = localStorage.getItem('selectedDate')
                let formattedDate = '';
                if (selectedDateFromLocalStorage) {
                    const dateFromLocalStorage = new Date(selectedDateFromLocalStorage);
                    const utcDate = new Date(dateFromLocalStorage.getUTCFullYear(), dateFromLocalStorage.getUTCMonth(), dateFromLocalStorage.getUTCDate());
                    formattedDate = formatDateToYYYYMMDD(utcDate);
                    setSelectedDate(new Date(utcDate))
                } else {
                    formattedDate = formatDateToYYYYMMDD(selectedDate);
                    localStorage.setItem('selectedDate', formattedDate)
                }
                fetchReservationsAndAbsences();
            } catch (error) {
            }
            hideLoadingWithDelay()
            return;
        }

        if (!showSelectDate) return;

        setIsLoading(true)
        try {
            const formattedDate = formatDateToYYYYMMDD(selectedDate);
            localStorage.setItem('selectedDate', formattedDate)
            fetchReservationsAndAbsences();
        } catch (error) {
        }
        setShowSelectDate(!showSelectDate);
        hideLoadingWithDelay()
    }, [selectedDate, firstRender]);

    useEffect(() => {
        fetchReservationsAndAbsences();
    }, [JSON.stringify(selectedBarbers), selectedDate]);

    const fetchReservationsAndAbsences = async () => {
        setIsLoading(true)
        const formattedDate = formatDateToYYYYMMDD(selectedDate);

        try {
            const responses = await Promise.all(selectedBarbers.map(barber =>
                api.get(`/agendamentos_completos/date/with_ausences/${formattedDate}/${barber.barbearia_usuario_id}`)
            ));

            const data = responses.map((response, index) => ({
                barbearia_usuario_id: selectedBarbers[index].barbearia_usuario_id,
                agendamentos_ausencias: response.data
            }));
            
            setReservationsAndAusences(data)
        } catch (error) {
            console.error("Failed to fetch reservations and absences:", error);
        } finally {
            hideLoadingWithDelay()
        }
    };

    const handleGoToReservation = (id: number) => {
        localStorage.setItem('selectedDate', formatDateToYYYYMMDD(selectedDate))
        navigate(`/reserva/concluida/${id}`)
    }

    const handleNewReservation = () => {
        navigate('/reserva')
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            {/* {JSON.stringify(selectedBarbers)} */}
            <MediumText bold>Agenda</MediumText>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray}>Selecione a data de filtro</NormalText>
                <SelectedAndUpdateButtonContainer>
                    <SelectedDate onClick={handleSelectDate}>
                        <FaCalendarCheck size={15} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray} bold>{formatDateToDDMMYYYY(selectedDate)}</NormalText>
                    </SelectedDate>
                    <UpdateButton onClick={() => fetchReservationsAndAbsences()}>
                        {/* <NormalText bold>Atualizar</NormalText> */}
                        <FaSync size={15} color={colors.mediumGray} />
                    </UpdateButton>
                </SelectedAndUpdateButtonContainer>
                {/* <SelectedDate onClick={handleSelectDate}>
                    <FaCalendarCheck size={15} color={colors.mediumGray} />
                    <NormalText color={colors.mediumGray} bold>{formatDateToDDMMYYYY(selectedDate)}</NormalText>
                </SelectedDate> */}
            </LabelAndValueContainer>
            {showSelectDate && (
                <Calendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    disablePast={false}
                    fixedWeekNumber={1}
                />
            )}

            <TwoButtonsContainer>
                <NewReservationButton onClick={() => setIsModalSchedulesOpen(true)}>
                    <FaPlus size={15} color={colors.white} />
                    <NormalText color={colors.white}>Agendamento</NormalText>
                </NewReservationButton>
                <NewReservationButton onClick={() => setIsModalFiltersOpen(true)}>
                    <FaFilter size={15} color={colors.white} />
                    <NormalText color={colors.white}>Filtros</NormalText>
                </NewReservationButton>
            </TwoButtonsContainer>


            {reservationsAndAusences.map(reservationOrAusence =>
            (
                <GroupContainer>
                    {selectedBarbers && (
                         <MediumText bold>Barbeiro: {selectedBarbers.find(
                            (barber: BarberType) => barber.barbearia_usuario_id === reservationOrAusence.barbearia_usuario_id
                        )?.nome}</MediumText>
                    )}
                   
                    {reservationOrAusence.agendamentos_ausencias.length === 0 && <NormalText>Nenhum agendamento para este dia</NormalText>}
                    {reservationOrAusence.agendamentos_ausencias.length > 0 && <NormalText>Você tem {reservationOrAusence.agendamentos_ausencias.length} agendamentos(s) para este dia</NormalText>}
                    <ReservationsContainer>
                        {reservationOrAusence.agendamentos_ausencias.map(reservationOrAusence => {
                            if (reservationOrAusence.agendamento) {
                                const { id } = reservationOrAusence.agendamento.agendamento
                                const { status_agendamento_id } = reservationOrAusence.agendamento.agendamento
                                return (
                                    <>

                                        <ReservationContainer
                                            key={reservationOrAusence.agendamento.agendamento.id}
                                            onClick={() => handleGoToReservation(id)}
                                            status_agendamento_id={status_agendamento_id}
                                        >
                                            <HourContainer>
                                                <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>{getHourFromDateTime(reservationOrAusence.agendamento.agendamento.data_agendamento)}</NormalText>
                                                <NormalText color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>{reservationOrAusence.agendamento.agendamento_servicos.reduce((acc, service) => acc + service.tempo_estimado, 0)} min</NormalText>
                                            </HourContainer>
                                            <DetailContainer>
                                                <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>Cliente: {reservationOrAusence.agendamento.cliente_nao_cadastrado.nome}</NormalText>
                                                <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>Serviço: {reservationOrAusence.agendamento.servicos.map(service => service.nome).join(', ')}</NormalText>
                                                <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>Telefone: {reservationOrAusence.agendamento.cliente_nao_cadastrado.telefone}</NormalText>
                                            </DetailContainer>
                                        </ReservationContainer>
                                    </>
                                )
                            }
                            if (reservationOrAusence.ausencia) {
                                const horaInicio = new Date(`1970-01-01T${reservationOrAusence.ausencia.hora_inicio}Z`);
                                const horaFim = new Date(`1970-01-01T${reservationOrAusence.ausencia.hora_fim}Z`);
                                const differenceInMinutes = (horaFim.getTime() - horaInicio.getTime()) / (1000 * 60);

                                const { id } = reservationOrAusence.ausencia

                                return (
                                    <AusenceContainer onClick={() => navigate(`/barber/ausencias/ausencia/${id}`)}>
                                        <HourContainer>
                                            <NormalText bold >{formatHourToHHMM(reservationOrAusence.ausencia.hora_inicio)}</NormalText>
                                            <NormalText >{differenceInMinutes} min</NormalText>
                                        </HourContainer>
                                        <DetailContainer>
                                            <NormalText>Ausência</NormalText>
                                            <NormalText>Motivo: {reservationOrAusence.ausencia.descricao}</NormalText>
                                        </DetailContainer>
                                    </AusenceContainer>
                                )
                            }
                        })}
                    </ReservationsContainer>
                </GroupContainer>
            )

            )}

            <GenericModal
                open={isModalSchedulesOpen}
                onClose={() => setIsModalSchedulesOpen(false)}
            >
                <MediumText bold>Selecione uma opção de reserva</MediumText>
                <NewReservationButtonModal onClick={handleNewReservation}>
                    <NormalText bold>Novo Agendamento</NormalText>
                </NewReservationButtonModal>
                <NewReservationButtonModal onClick={() => navigate(`/barber/agendamento-recorrente`)}>
                    <NormalText bold>Novo Agendamento com pacotes</NormalText>
                </NewReservationButtonModal>
                <NewAusenceButton onClick={() => navigate(`/barber/ausencias/ausencia/`)}>
                    <NormalText bold>Nova Ausência</NormalText>
                </NewAusenceButton>
            </GenericModal>

            <GenericModal
                open={isModalFiltersOpen}
                onClose={() => setIsModalFiltersOpen(false)}
            >
                <>
                    <NormalText>Filters</NormalText>
                    <BarberSelector
                        selectedBarbers={selectedBarbers}
                        setSelectedBarbers={setSelectedBarbers}
                        onlyOneBarber={false}
                    />
                </>
            </GenericModal>
            {/* <DailyScheduleTable /> */}

        </>
    )
}