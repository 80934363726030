import React, { useContext, useEffect, useState } from "react"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import MediumText from "../../../../components/texts/MediumText";
import { useNavigate } from "react-router-dom";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { RecurrentReservationScheduleEditType, ReservationAusencesConflictListType, ReservationAusencesListType } from "../../../../types/reservation";
import { UsuarioFotoType } from "../../../../types/user";
import { BarbersType, BarberType } from "../../../../types/barber";
import colors from "../../../../style/colors";
import { FaRegUser } from "react-icons/fa";
import GenericButton from "../../../../components/GenericButton";
import GenericModal from "../../../../components/GenericModal";
import NormalText from "../../../../components/texts/NormalText";
import SmallText from "../../../../components/texts/SmallText";
import { api } from "../../../../config/api";
import { BarbershopContext } from "../../../../context/babershop";
import { RecurrentReservationContext } from "../../../../context/RecurrentReservation";
import { formatDateToDDMMYYYY, getHourFromDateTime } from "../../../../functions/date";
import { formatHourToHHMM } from "../../../../functions/hour";
import { ResumeContainer, InputValue, ConfirmationButton } from "../../../DoningReservation/style";
import { GoBackButton, LabelAndValueContainer, Separator } from "../../../globalStyles";
import { BarbersContainer, BarberImageAndNameContainer, BarberImage, BarberName } from "../../../InProgressReservation/style";
import { ReservationsContainer, ReservationContainer, HourContainer, DetailContainer, AusenceContainer } from "../../Schedules";
import BarberSelector from "../../../../components/BarberSelector";
import { toast } from "react-toastify";


export default function EditReservations() {
    const navigate = useNavigate();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const [barbers, setBarbers] = useState<BarbersType>([]);
    const [barbersPhotos, setBarbersPhotos] = useState<UsuarioFotoType[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState<RecurrentReservationScheduleEditType>({
        data: '',
        hora: '',
        // barbearia_usuario_id: 0,
        barbearia_usuario: {
            barbearia_usuario_id: 0,
            nome: ''
        },
        index: 0
    });
    const [conflictWithCurrentSchedule, setConflictWithCurrentSchedules] = useState<ReservationAusencesListType>([]);
    const [conflicsWithAllSchedules, setConflictWithAllSchedules] = useState<ReservationAusencesConflictListType>([]);

    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const recurrentReservationContext = useContext(RecurrentReservationContext);
    const {
        selectedServices,
        recurrentReservationsSchedules,
        setRecurrentReservationsSchedules,
        setBarberPreference,
        barberPreference
    } = recurrentReservationContext;

    useEffect(() => {
        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${barbershop?.id}`)
                setBarbers(response.data)
                setBarberPreference([response.data[0]])
            } catch (error) {
            }
        }

        const getBarbersPhotos = async () => {
            try {
                const response = await api.get(`/usuarios_foto/barbearia/${barbershop?.id}`)
                setBarbersPhotos(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getBarbers()
        getBarbersPhotos()
        hideLoadingWithDelay()
    }, [barbershop?.id,])

    useEffect(() => {
        setIsLoading(true)
        getExistingScheduleAndAusences()
        hideLoadingWithDelay()
    }, [currentSchedule])

    useEffect(() => {
        setIsLoading(true)
        setCurrentSchedule({
            ...currentSchedule,
            barbearia_usuario: barberPreference[0]
        })
        hideLoadingWithDelay()
    }, [barberPreference])

    useEffect(() => {
        setIsLoading(true)
        getExistingScheduleAndAusencesForAllRecurrentSchedules()
        hideLoadingWithDelay()
    }, [recurrentReservationsSchedules])

    const openEditSchedule = (index: number) => {
        setIsLoading(true)
        const schedule = {
            ...recurrentReservationsSchedules[index],
            index: index
        }
        setCurrentSchedule(schedule);
        setIsModalOpen(true);
        hideLoadingWithDelay()
    }

    const saveSchedule = () => {
        const updatedSchedules = [...recurrentReservationsSchedules];
        updatedSchedules[currentSchedule.index] = currentSchedule;
        setRecurrentReservationsSchedules(updatedSchedules);
        setIsModalOpen(false);
    }

    const getExistingScheduleAndAusences = async () => {
        try {
            const estimatedTime = selectedServices.reduce((total, service) => total + service.tempo_estimado, 0);            
            const response = await api.get(`/horarios/agendamentos_ausencias_por_data_hora?barbearia_usuario_id=${currentSchedule?.barbearia_usuario.barbearia_usuario_id}&date_filter=${currentSchedule?.data}&time_filter=${currentSchedule?.hora}&tempo_estimado_servicos=${estimatedTime}`);
            setConflictWithCurrentSchedules(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getExistingScheduleAndAusencesForAllRecurrentSchedules = async () => {
        try {
            const estimatedTime = selectedServices.reduce((total, service) => total + service.tempo_estimado, 0);
            const schedules = recurrentReservationsSchedules.map((schedule, index) => ({
                data: schedule.data,
                hora: schedule.hora,
                barbearia_usuario_id: schedule.barbearia_usuario.barbearia_usuario_id,
                tempo_estimado_servicos: estimatedTime,
                index: index
            }));
            // console.log(schedules)
            const response = await api.post(`/horarios/agendamentos_ausencias_por_data_hora_lista`, schedules);
            // console.log(response.data)
            setConflictWithAllSchedules(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {

    const handleNext = () => {
        let hasConflict = false;
        conflicsWithAllSchedules.map(conflict => {
            if (conflict.agendamentos_ausencias.length > 0) {
                hasConflict = true;
            }
        })

        if (hasConflict) {
            toast.error('Você tem agendamentos com conflitos de horário, por favor ajuste para que não haja conflitos')
            return;
        }


        navigate('/barber/agendamento-recorrente/confirmacao')
    }



    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText>Agendamento recorrente</MediumText>
            
            {recurrentReservationsSchedules.length > 0 && (
                <>
                    {recurrentReservationsSchedules.map((schedule, index) => (
                        <ResumeContainer key={index} onClick={() => openEditSchedule(index)} >
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} bold>Data</SmallText>
                                <NormalText color={colors.mediumGray}>{formatDateToDDMMYYYY(new Date(schedule.data))}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} bold>Barbeiro</SmallText>
                                <NormalText color={colors.mediumGray}>{barbers.find(barber => barber.id === schedule.barbearia_usuario.barbearia_usuario_id)?.nome}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} bold>Hora</SmallText>
                                <NormalText color={colors.mediumGray}>{formatHourToHHMM(schedule.hora)}</NormalText>
                            </LabelAndValueContainer>
                            {conflicsWithAllSchedules.map(conflict => {
                                if (conflict.index === index) {
                                    // return conflict.agendamentos_ausencias.length;
                                    if(conflict.agendamentos_ausencias.length > 0) {
                                        return <NormalText color={colors.red}>Você tem {conflict.agendamentos_ausencias.length} agendamentos(s) existentes para este dia/hora</NormalText>
                                    }
                                    return <NormalText color={colors.green}>Agenda disponível</NormalText>
                                }
                                return null;
                            }).filter(length => length !== null)}
                        </ResumeContainer>
                    )
                    )}

                    <GenericModal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                    >
                        {currentSchedule && (
                            <>
                                <MediumText>Editar agendamento</MediumText>
                                {/* <LabelAndValueContainer>
                                    <NormalText color={colors.darkGray} bold>Escolha seu barbeiro</NormalText>
                                    <BarbersContainer>
                                        {barbers.map((barber: BarberType, index: number) => {
                                            const barberPhoto = barbersPhotos.find((barberPhoto: UsuarioFotoType) => barberPhoto.usuario_id === barber.id)

                                            return (
                                                <>
                                                    {barberPhoto?.foto ? (
                                                        <BarberImageAndNameContainer key={index} onClick={() => setCurrentSchedule(
                                                            {
                                                                ...currentSchedule,
                                                                // barbearia_usuario_id: barber.barbearia_usuario_id
                                                                barbearia_usuario: {
                                                                    barbearia_usuario_id: barber.barbearia_usuario_id,
                                                                    nome: barber.nome
                                                                }
                                                            }
                                                        )} selected={currentSchedule.barbearia_usuario.barbearia_usuario_id === barber.barbearia_usuario_id}>
                                                            <BarberImage src={process.env.REACT_APP_API_URL + barberPhoto.foto} alt="Barber Image" />
                                                            <BarberName>{barber.nome}</BarberName>
                                                        </BarberImageAndNameContainer>
                                                    ) : (
                                                        <BarberImageAndNameContainer key={index} onClick={() => setCurrentSchedule(
                                                            {
                                                                ...currentSchedule,
                                                                // barbearia_usuario_id: barber.barbearia_usuario_id
                                                                barbearia_usuario: {
                                                                    barbearia_usuario_id: barber.barbearia_usuario_id,
                                                                    nome: barber.nome
                                                                }
                                                            }
                                                        )} selected={currentSchedule.barbearia_usuario.barbearia_usuario_id === barber.barbearia_usuario_id}>
                                                            <FaRegUser color={colors.mediumGray} size={20} />
                                                            <BarberName>{barber.nome}</BarberName>
                                                        </BarberImageAndNameContainer>
                                                    )}
                                                </>
                                            )
                                        })
                                        }
                                    </BarbersContainer>
                                </LabelAndValueContainer> */}
                                 <BarberSelector
                                    selectedBarbers={barberPreference}
                                    setSelectedBarbers={setBarberPreference}
                                    onlyOneBarber={true}
                                />
                                <Separator />
                                <Separator />
                                <LabelAndValueContainer>
                                    <NormalText color={colors.mediumGray} bold>Data</NormalText>
                                    <InputValue
                                        type="date"
                                        value={currentSchedule?.data}
                                        onChange={(e) => setCurrentSchedule({ ...currentSchedule, data: e.target.value })}
                                    />
                                </LabelAndValueContainer>
                                <LabelAndValueContainer>
                                    <NormalText color={colors.mediumGray} bold>Hora</NormalText>
                                    <InputValue
                                        type="time"
                                        value={currentSchedule?.hora}
                                        onChange={(e) => setCurrentSchedule({ ...currentSchedule, hora: e.target.value })}
                                    />
                                </LabelAndValueContainer>
                                <Separator />
                                {conflictWithCurrentSchedule.length === 0 && <NormalText>Nenhum agendamento para este dia</NormalText>}
                                {conflictWithCurrentSchedule.length > 0 && <NormalText>Você tem {conflictWithCurrentSchedule.length} agendamentos(s) para este dia/hora
                                </NormalText>}
                                <ReservationsContainer>
                                    {conflictWithCurrentSchedule.map(reservationOrAusence => {
                                        if (reservationOrAusence.agendamento) {
                                            const { id } = reservationOrAusence.agendamento.agendamento
                                            const { status_agendamento_id } = reservationOrAusence.agendamento.agendamento
                                            return (
                                                <>
                                                    <ReservationContainer
                                                        key={reservationOrAusence.agendamento.agendamento.id}
                                                        // onClick={() => handleGoToReservation(id)}
                                                        status_agendamento_id={status_agendamento_id}
                                                    >
                                                        <HourContainer>
                                                            <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>{getHourFromDateTime(reservationOrAusence.agendamento.agendamento.data_agendamento)}</NormalText>
                                                            <NormalText color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>{reservationOrAusence.agendamento.agendamento_servicos.reduce((acc, service) => acc + service.tempo_estimado, 0)} min</NormalText>
                                                        </HourContainer>
                                                        <DetailContainer>
                                                            <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>Cliente: {reservationOrAusence.agendamento.cliente_nao_cadastrado.nome}</NormalText>
                                                            <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>Serviço: {reservationOrAusence.agendamento.servicos.map(service => service.nome).join(', ')}</NormalText>
                                                            <NormalText bold color={status_agendamento_id === 4 ? colors.red : colors.darkGray}>Telefone: {reservationOrAusence.agendamento.cliente_nao_cadastrado.telefone}</NormalText>
                                                        </DetailContainer>
                                                    </ReservationContainer>
                                                </>
                                            )
                                        }
                                        if (reservationOrAusence.ausencia) {
                                            const horaInicio = new Date(`1970-01-01T${reservationOrAusence.ausencia.hora_inicio}Z`);
                                            const horaFim = new Date(`1970-01-01T${reservationOrAusence.ausencia.hora_fim}Z`);
                                            const differenceInMinutes = (horaFim.getTime() - horaInicio.getTime()) / (1000 * 60);

                                            const { id } = reservationOrAusence.ausencia

                                            return (
                                                <AusenceContainer onClick={() => navigate(`/barber/ausencias/ausencia/${id}`)}>
                                                    <HourContainer>
                                                        <NormalText bold >{formatHourToHHMM(reservationOrAusence.ausencia.hora_inicio)}</NormalText>
                                                        <NormalText >{differenceInMinutes} min</NormalText>
                                                    </HourContainer>
                                                    <DetailContainer>
                                                        <NormalText>Ausência</NormalText>
                                                        <NormalText>Motivo: {reservationOrAusence.ausencia.descricao}</NormalText>
                                                    </DetailContainer>
                                                </AusenceContainer>
                                            )
                                        }
                                    }
                                    )}
                                </ReservationsContainer>
                                <GenericButton
                                    onClick={saveSchedule}
                                >
                                    <NormalText>Salvar</NormalText>
                                </GenericButton>
                            </>
                        )}
                    </GenericModal>
                    <ConfirmationButton
                        onClick={handleNext}
                    >
                        <NormalText color={colors.white}>Próximo</NormalText>
                    </ConfirmationButton>
                    <GoBackButton onClick={() => navigate(-1)}>
                        <MediumText bold>Voltar</MediumText>
                    </GoBackButton>
                </>
            )}


        </>
    );
}