import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BarbershopContext } from "../../../../context/babershop";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import { GetReservationsType, RecurrentReservationData, RecurrentReservationType } from "../../../../types/reservation";
import { api } from "../../../../config/api";
import { toast } from "react-toastify";
import NormalText from "../../../../components/texts/NormalText";
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles";
import { InputValue, ResumeContainer, WhatsAppButton } from "../../../DoningReservation/style";
import colors from "../../../../style/colors";
import SmallText from "../../../../components/texts/SmallText";
import { getDateFromDateTime, getHourFromDateTime } from "../../../../functions/date";
import MediumText from "../../../../components/texts/MediumText";
import { AuthContext } from "../../../../context/authContext";
import { FaWhatsapp } from "react-icons/fa";

export default function ResumeRecurrentReservation() {
    const navigate = useNavigate()

    const barberShopContext = useContext(BarbershopContext)
    const { user } = useContext(AuthContext);
    const { barbershop } = barberShopContext

    const { id } = useParams<{ id: string }>();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const [recurrentReservationData, setRecurrentReservationData] = useState<RecurrentReservationData>()
    const [recurrentReservationType, setRecurrentReservationType] = useState<RecurrentReservationType>()
    const [reservations, setReservations] = useState<GetReservationsType>()

    // useEffect(() => {
    //     if (!barbershop) {
    //         navigate('/')
    //     }
    // }, [barbershop, navigate])

    useEffect(() => {
        if (id) {
            setIsLoading(true)
            const getRecurrentReservationData = async () => {
                try {
                    console.log(id)
                    const response = await api.get(`/agendamentos_recorrentes/${id}`)
                    console.log(response)
                    if (response.status !== 200) {
                        toast.error("Erro ao buscar dados do agendamento recorrente")
                        return
                    }
                    setRecurrentReservationData(response.data)

                    const tipo_agendamento_recorrente_id = response.data.tipo_agendamento_recorrente_id
                    const responseType = await api.get(`/tipos_agendamento_recorrente/${tipo_agendamento_recorrente_id}`)
                    setRecurrentReservationType(responseType.data)
                    // 'agendamentos_completosrecurrent_id'

                    const responseReservations = await api.get(`/agendamentos_completos/recurrent_id/${id}`)
                    console.log(responseReservations.data)
                    setReservations(responseReservations.data)


                } catch (error) {
                    console.error(error)
                } finally {
                }
            }
            getRecurrentReservationData()
            hideLoadingWithDelay()
        }
    }, [id])


    const handleGoToWhatsAppClientChat = () => {
        if (reservations) {
            const phone = reservations[0].cliente_nao_cadastrado.telefone
            let text = `Olá, ${reservations[0].cliente_nao_cadastrado.nome}! Aqui é da barbearia *${reservations[0].barbearia.nome}*.`;
            text = text.concat(`\n\nVocê tem os seguintes agendamentos: \n`);
            reservations.forEach(reservation => {
                text = text.concat(`\n- ${getDateFromDateTime(reservation.agendamento.data_agendamento)} às ${getHourFromDateTime(reservation.agendamento.data_agendamento)} com ${reservation.usuario.nome}`);
            });
            text = text.concat(`\n\nAguardanmos você! Atenciosamente, ${reservations[0].barbearia.nome}`);
            window.open(`https://api.whatsapp.com/send?phone=55${phone}&text=${text}`, '_blank')

            // window.open(`https://api.whatsapp.com/send?phone=55${phone}`, '_blank')
        }
    }


    return <>
        <LoadingFreezeScreen isLoading={isLoading} />

        {reservations && (
            <>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Nome do cliente</NormalText>
                    <InputValue
                        type="text"
                        value={reservations[0].cliente_nao_cadastrado.nome}
                        disabled
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                    <InputValue
                        type="number"
                        value={reservations[0].cliente_nao_cadastrado.telefone}
                        disabled
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Barbearia</NormalText>
                    <InputValue
                        type="text"
                        value={reservations[0].barbearia.nome}
                        disabled
                    />
                </LabelAndValueContainer>
                {reservations.map((reservation, index) => (
                    <ResumeContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Barbeiro</SmallText>
                            <NormalText color={colors.mediumGray}>{reservation.usuario.nome}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Endereço</SmallText>
                            <NormalText color={colors.mediumGray}>{barbershop?.endereco}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Serviços</SmallText>
                            {reservation.servicos.map(service => (
                                <NormalText color={colors.mediumGray} key={service.id}>- {service.nome}</NormalText>
                            ))}
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Data</SmallText>
                            <NormalText color={colors.mediumGray}>{getDateFromDateTime(reservation.agendamento.data_agendamento)}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Total</SmallText>
                            <NormalText color={colors.mediumGray} bold>R$ {reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}</NormalText>
                        </LabelAndValueContainer>
                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray} bold>Hora</SmallText>
                            <NormalText color={colors.mediumGray}>{getHourFromDateTime(reservation.agendamento.data_agendamento)}</NormalText>
                        </LabelAndValueContainer>
                    </ResumeContainer>
                ))}

                {user?.id && (
                    <>
                        <WhatsAppButton onClick={handleGoToWhatsAppClientChat}>
                            <FaWhatsapp size={30} />
                            <NormalText color="white" bold>Entrar em contato com o cliente</NormalText>
                        </WhatsAppButton>
                        <GoBackButton
                            onClick={() => navigate('/barber/reservas')}
                        >
                            <MediumText bold color={colors.mediumGray}>Voltar</MediumText>
                        </GoBackButton>
                    </>
                )}

            </>

        )}


    </>
}