import React, { useContext, useEffect, useState } from "react"
import colors from "../../style/colors"
import { AddServiceButton, LabelAndValueContainer, ReservationButton, Separator, Service, Services, ServiceValueAndTimeContainer, TotalContainer, TotalsContainer, TotalWithReservationButtonContainer } from "../globalStyles"
import { FaPlus, FaRegUser } from "react-icons/fa"
import NormalText from "../../components/texts/NormalText"
import MediumText from "../../components/texts/MediumText"
import SmallText from "../../components/texts/SmallText"
import { useNavigate } from "react-router-dom"
import { api } from "../../config/api"
import { BarbershopContext } from "../../context/babershop"
import { BarberType, BarbersType } from "../../types/barber"
import Calendar from "../../components/Calendar"
import { BarberImage, BarberImageAndNameContainer, BarberName, BarbersContainer, Hour, HoursAvailable } from "./style"
import { InprogressReservationContext } from "../../context/inProgressReservation"
import { formatDateToYYYYMMDD } from "../../functions/date"
import { LoadingHook } from "../../hooks/LoadingHook"
import LoadingFreezeScreen from "../../components/LoadingFreezeScreen"
import { toast } from "react-toastify"
import { UsuarioFotosType, UsuarioFotoType } from "../../types/user"
import BarberSelector from "../../components/BarberSelector"

export default function InProgressReservation() {
    const navigate = useNavigate()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const [barbers, setBarbers] = useState<BarbersType>([])
    const [hoursAvailable, setHoursAvailable] = useState([])
    const [barbersPhotos, setBarbersPhotos] = useState<UsuarioFotosType>([])

    const inprogressReservationContext = useContext(InprogressReservationContext)
    const {
        selectedBaber,
        selectedDate,
        selectedHour,
        selectedServices,
        total,
        setSelectedBarber,
        setSelectedDate,
        setSelectedHour,
        getEstimatedTime
    } = inprogressReservationContext

    useEffect(() => {
        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${barbershop?.id}`)
                setBarbers(response.data)
                setSelectedBarber([response.data[0]])
            } catch (error) {
            }
        }

        const getBarbersPhotos = async () => {
            try {
                const response = await api.get(`/usuarios_foto/barbearia/${barbershop?.id}`)
                setBarbersPhotos(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getBarbers()
        getBarbersPhotos()
        hideLoadingWithDelay()
    }, [barbershop, setSelectedBarber])

    // const handleSelectedBarber = (barber: BarberType) => {
    //     setSelectedBarber(barber)
    // }

    useEffect(() => {
        setIsLoading(true)
        const getHoursAvailable = async () => {
            try {
                const formattedDate = formatDateToYYYYMMDD(selectedDate);
                const response = await api.get(`/horarios/?barbearia_usuario_id=${selectedBaber[0]?.barbearia_usuario_id}&date=${formattedDate}&tempo_estimado_servicos=${getEstimatedTime()}`)
                setHoursAvailable(response.data);
            } catch (error) {
            }
        }

        getHoursAvailable()
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBaber, selectedDate, barbershop])

    const handleSelectHour = (index: number) => {
        setSelectedHour(hoursAvailable[index])
    }

    const handleNavigateToSelectService = () => {
        navigate('servico')
    }

    const handleNavigateToDoneReservation = () => {
        setIsLoading(true)

        if (!selectedBaber)
            toast.error("Selecione um barbeiro")
        if (selectedServices.length === 0)
            toast.error("Selecione um serviço")
        if (!selectedHour)
            toast.error("Selecione um horário")

        hideLoadingWithDelay()
        if (!selectedBaber || selectedServices.length === 0 || !selectedHour) {
            return
        }

        navigate('concluir')
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText color={colors.darkGray} bold>Faça sua reserva</MediumText>
            {selectedServices.length > 0 && (
                <>
                    {/* <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Escolha seu barbeiro</NormalText>
                        <BarbersContainer>
                            {barbers.map((barber: BarberType, index: number) => {
                                const barberPhoto = barbersPhotos.find((barberPhoto: UsuarioFotoType) => barberPhoto.usuario_id === barber.id)

                                return (
                                    <>
                                        {barberPhoto?.foto ? (
                                            <BarberImageAndNameContainer key={index} onClick={() => handleSelectedBarber(barber)} selected={selectedBaber?.id === barber.id}>
                                                <BarberImage src={process.env.REACT_APP_API_URL + barberPhoto.foto} alt="Barber Image" />
                                                <BarberName>{barber.nome}</BarberName>
                                            </BarberImageAndNameContainer>
                                        ) : (
                                            <BarberImageAndNameContainer key={index} onClick={() => handleSelectedBarber(barber)} selected={selectedBaber?.id === barber.id}>
                                                <FaRegUser color={colors.mediumGray} size={20} />
                                                <BarberName>{barber.nome}</BarberName>
                                            </BarberImageAndNameContainer>
                                        )}
                                    </>
                                )
                            })
                            }
                        </BarbersContainer>
                    </LabelAndValueContainer> */}
                    <BarberSelector
                        selectedBarbers={selectedBaber}
                        setSelectedBarbers={setSelectedBarber}
                        onlyOneBarber={true}
                    />
                    <Separator />
                </>
            )}
            <LabelAndValueContainer>
                <NormalText color={colors.darkGray} bold>Serviços</NormalText>
                <Services>
                    {selectedServices.length === 0 && (
                        <NormalText color={colors.mediumGray} bold>Nenhum serviço foi selecionado</NormalText>
                    )}
                    {selectedServices.map((service, index) => (
                        <Service key={index}>
                            <NormalText color={colors.mediumGray} bold>{service.nome}</NormalText>
                            <ServiceValueAndTimeContainer>
                                <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                            </ServiceValueAndTimeContainer>
                        </Service>
                    ))}
                    {selectedServices.length === 0 && (
                        <AddServiceButton onClick={handleNavigateToSelectService}>
                            <FaPlus color={colors.white} size={20} />
                            <NormalText color={colors.white}>Selecionar serviços</NormalText>
                        </AddServiceButton>
                    )
                    }
                </Services>
            </LabelAndValueContainer>
            {selectedServices.length > 0 &&
                <>
                    <Separator />
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Escolha a data para o corte</NormalText>
                        <Calendar
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                        />
                    </LabelAndValueContainer>
                    <Separator />
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Horários disponíveis</NormalText>
                        {hoursAvailable.length === 0 && (
                            <NormalText color={colors.mediumGray} bold>Nenhum horário disponível</NormalText>
                        )}
                        
                        <HoursAvailable>
                            {hoursAvailable.map((hour, index) => (
                                <Hour key={index} selected={selectedHour === hour} onClick={() => handleSelectHour(index)}>
                                    <NormalText color={colors.mediumGray} bold>{hour}</NormalText>
                                </Hour>
                            ))}
                        </HoursAvailable>
                    </LabelAndValueContainer>
                    <Separator />
                    <TotalWithReservationButtonContainer>
                        <TotalsContainer>
                            <TotalContainer>
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Tempo estimado</SmallText>
                                    <NormalText color={colors.darkGray} bold>{total.estimatedTime} min</NormalText>
                                </LabelAndValueContainer>
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Valor Total</SmallText>
                                    <NormalText color={colors.darkGray} bold>R$ {total.total}</NormalText>
                                </LabelAndValueContainer>
                            </TotalContainer>
                            <TotalContainer>
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Dia</SmallText>
                                    <NormalText color={colors.darkGray} bold>{total.date}</NormalText>
                                </LabelAndValueContainer>
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Hora</SmallText>
                                    <NormalText color={colors.darkGray} bold>{total.hour}</NormalText>
                                </LabelAndValueContainer>
                            </TotalContainer>

                        </TotalsContainer>
                        <ReservationButton onClick={handleNavigateToDoneReservation}>
                            <NormalText color={colors.white} bold>Reservar</NormalText>
                        </ReservationButton>
                    </TotalWithReservationButtonContainer>
                </>
            }
        </>
    )
}