import React, { useEffect, useState } from "react";
import { BarbersType, BarberType } from "../types/barber";
import { ServicesType } from "../types/services";
import { TotalType } from "../types/reservation";
import { formatDateToDDMMYYYY } from "../functions/date";

export type NonRegistredClientType = {
    id: number;
    nome: string;
    telefone: string;
    email: string;
};

type InProgressReservationContextType = {
    // selectedBaber: BarberType | null;
    selectedBaber: BarbersType;
    selectedDate: Date;
    selectedHour: string | null;
    selectedServices: ServicesType;
    total: TotalType;
    nonRegistredClient: NonRegistredClientType;
    // setSelectedBarber: (barber: BarberType) => void;
    setSelectedBarber: (barber: BarbersType) => void;
    setSelectedDate: (date: Date) => void;
    setSelectedHour: (hour: string) => void;
    setSelectedServices: (services: ServicesType) => void;    
    setTotal: (total: TotalType) => void;
    setNonRegistredClient: (client: NonRegistredClientType) => void;
    getEstimatedTime: () => number;
    resetValuesOfContext: () => void;
};

export const  InprogressReservationContext = React.createContext<InProgressReservationContextType>({
    // selectedBaber: null,
    selectedBaber: [],
    selectedDate: new Date(),
    selectedHour: null,
    selectedServices: [],
    total: {
        date: formatDateToDDMMYYYY(new Date()),
        hour: "",
        estimatedTime: 0,
        total: 0
    },
    nonRegistredClient: {
        id: 0,
        nome: "",
        telefone: "",
        email: ""
    },
    setSelectedBarber: () => {},
    setSelectedDate: () => {},
    setSelectedHour: () => {},
    setSelectedServices: () => {},
    setTotal: () => {},
    setNonRegistredClient: () => {},
    getEstimatedTime: () => 0,
    resetValuesOfContext: () => {}
});

export function InProgressReservationProvider({ children }: { children: React.ReactNode }){
    // const [inProgressReservation, setInProgressReservation] = useState<InProgressReservationType | null>(null);
    // const [selectedBaber, setSelectedBarber] = useState<any>(null)
    const [selectedBaber, setSelectedBarber] = useState<BarbersType>([])
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [selectedHour, setSelectedHour] = useState<string | null>(null)
    const [selectedServices, setSelectedServices] = useState<ServicesType>([])
    const [total, setTotal] = useState<TotalType>({
        date: formatDateToDDMMYYYY(new Date()),
        hour: "",
        estimatedTime: 0,
        total: 0
    })
    const [nonRegistredClient, setNonRegistredClient] = useState<NonRegistredClientType>({
        id: 0,
        nome: "",
        telefone: "",
        email: ""
    })

    useEffect(() => {
        setSelectedHour(null)
    } , [selectedServices])

    useEffect(() => {
        setSelectedHour(null)
    }, [selectedDate])

    useEffect(() => {
        let estimatedTime = 0
        let total = 0
        selectedServices.forEach(service => {
            estimatedTime += service.tempo_estimado
            total += service.preco
        })

        setTotal({
            date: formatDateToDDMMYYYY(selectedDate),
            hour: selectedHour || "",
            estimatedTime,
            total
        })
    }, [selectedServices, selectedDate, selectedHour])

    const getEstimatedTime = () => {
        let estimatedTime = 0
        selectedServices.forEach(service => {
            estimatedTime += service.tempo_estimado
        })

        return estimatedTime
    }

    const resetValuesOfContext = () => {
        // setSelectedBarber(null)
        setSelectedBarber([])
        setSelectedDate(new Date())
        setSelectedHour(null)
        setSelectedServices([])
        setTotal({
            date: formatDateToDDMMYYYY(new Date()),
            hour: "",
            estimatedTime: 0,
            total: 0
        })
        setNonRegistredClient({
            id: 0,
            nome: "",
            telefone: "",
            email: ""
        })
    }

    return (
        <InprogressReservationContext.Provider value={{ 
            selectedBaber, 
            selectedDate, 
            selectedHour, 
            selectedServices,
            total,
            nonRegistredClient,
            setSelectedBarber, 
            setSelectedDate, 
            setSelectedHour,
            setSelectedServices,
            setTotal,
            setNonRegistredClient,
            getEstimatedTime,
            resetValuesOfContext
        }}>
            {children}
        </InprogressReservationContext.Provider>
    );
};