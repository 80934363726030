import React from "react"
import { OpeningHoursType } from "../../../../types/barbershop"
import { api } from "../../../../config/api"
import { AuthContext } from "../../../../context/authContext"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import MediumText from "../../../../components/texts/MediumText"
import { ResumeContainer } from "../../../DoningReservation/style"
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles"
import SmallText from "../../../../components/texts/SmallText"
import NormalText from "../../../../components/texts/NormalText"
import colors from "../../../../style/colors"
import { useNavigate } from "react-router-dom"

interface DayWeekContainerProps {
    day: string;
    initHour: string | undefined;
    endHour: string | undefined;
    handleClick?: () => void;
}

const DayWeekContainer: React.FC<DayWeekContainerProps> = ({ day, initHour, endHour, handleClick }) => {
    return (
        <ResumeContainer onClick={handleClick}>
            <LabelAndValueContainer>
                <SmallText color={colors.mediumGray} bold>Dia da semana:</SmallText>
                <NormalText color={colors.mediumGray}>{day}</NormalText>
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <SmallText color={colors.mediumGray} bold>Horário de abertura:</SmallText>
                <NormalText color={colors.mediumGray}>{initHour}</NormalText>
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <SmallText color={colors.mediumGray} bold>Horário de fechamento:</SmallText>
                <NormalText color={colors.mediumGray}>{endHour}</NormalText>
            </LabelAndValueContainer>
        </ResumeContainer>
    )
}

export default function OpeningHoursList(){
    const {user} = React.useContext(AuthContext)
    const [openingHours, setOpeningHours] = React.useState<OpeningHoursType>([])
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const navigate = useNavigate()
    
    
    React.useEffect(() => {
        const getOpeningHours = async () => {
            try {
                const response = await api.get(`/horarios_atendimento/barbeiro/${user?.id}`)
                setOpeningHours(response.data)
            } catch (error) {
            }
        }

        getOpeningHours()
    }, [])

    const handleNavigate = (id: number | undefined, weekday: number) => {
        if (!id) 
            return navigate(`horario/${weekday}/`)

        navigate(`horario/${weekday}/${id}`)
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText bold>Barbearias</MediumText>

            <DayWeekContainer
                day="Segunda-feira"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 1)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 1)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 1)?.id, 1)}
            />
            <DayWeekContainer
                day="Terça-feira"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 2)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 2)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 2)?.id, 2)}
            />
            <DayWeekContainer
                day="Quarta-feira"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 3)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 3)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 3)?.id, 3)}
            />
            <DayWeekContainer
                day="Quinta-feira"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 4)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 4)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 4)?.id, 4)}
            />
            <DayWeekContainer
                day="Sexta-feira"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 5)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 5)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 5)?.id, 5)}
            />
            <DayWeekContainer
                day="Sábado"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 6)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 6)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 6)?.id, 6)}
            />
            <DayWeekContainer
                day="Domingo"
                initHour={openingHours.find(openingHour => openingHour.dia_semana === 7)?.hora_abertura}
                endHour={openingHours.find(openingHour => openingHour.dia_semana === 7)?.hora_fechamento}
                handleClick={() => handleNavigate(openingHours.find(openingHour => openingHour.dia_semana === 7)?.id, 7)}
            />
            <GoBackButton onClick={() => navigate(-1)}>
                <NormalText bold>Voltar</NormalText>
            </GoBackButton>
        </>
    )
}