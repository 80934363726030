import React, { useContext, useEffect, useState } from "react";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { useNavigate } from "react-router-dom";
import { AgendamentoType, RecurrentReservationScheduleEditType, RecurrentReservationsType } from "../../../../types/reservation";
import { BarbersType } from "../../../../types/barber";
import { UsuarioFotoType } from "../../../../types/user";
import { BarbershopContext } from "../../../../context/babershop";
import { RecurrentReservationContext } from "../../../../context/RecurrentReservation";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import NormalText from "../../../../components/texts/NormalText";
import { GoBackButton, LabelAndValueContainer } from "../../../globalStyles";
import { ConfirmationButton, InputValue, ResumeContainer } from "../../../DoningReservation/style";
import colors from "../../../../style/colors";
import SmallText from "../../../../components/texts/SmallText";
import { composeDateAndHour, formatDateToDDMMYYYYFromString, formatDateToYYYYMMDD } from "../../../../functions/date";
import MediumText from "../../../../components/texts/MediumText";
import { toast } from "react-toastify";
import { api } from "../../../../config/api";

export default function RecurrentReservationConfirmation() {
    const navigate = useNavigate();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const recurrentReservationContext = useContext(RecurrentReservationContext);
    const {
        selectedServices,
        recurrentReservationsSchedules,
        nonRegistredClient,
        initialDate,
        finalDate,
        recurrentReservationType,
        setNonRegistredClient,
        resetValuesOfContext
    } = recurrentReservationContext;

    const handleConfirmReservation = async () => {
        setIsLoading(true)

        if (!nonRegistredClient.nome || !nonRegistredClient.telefone) {
            toast.error("Preencha seu nome e telefone por favor")
            hideLoadingWithDelay()
            return
        }

        const phoneRegex = /^\d{2}\d{4,5}\d{4}$/
        if (!phoneRegex.test(nonRegistredClient.telefone)) {
            toast.error("Telefone inválido")
            hideLoadingWithDelay()
            return
        }

        const recurrentReservationsData = recurrentReservationsSchedules.map(schedule => {
            const [year, month, day] = schedule.data.split('-').map(Number);
            const date = new Date(Date.UTC(year, month - 1, day));
            date.setUTCHours(0, 0, 0, 0);

            const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            console.log(localDate); // Outputs the local date equivalent

            return {
                agendamento: {
                    barbearia_usuario_id: schedule.barbearia_usuario.barbearia_usuario_id,
                    data_agendamento: composeDateAndHour(localDate, schedule.hora)
                },
                cliente_nao_cadastrado: {
                    nome: nonRegistredClient.nome,
                    telefone: nonRegistredClient.telefone
                },
                agendamento_servicos: selectedServices.map(service => ({
                    servico_id: service.id,
                    valor: service.preco,
                    tempo_estimado: service.tempo_estimado
                }))
            }
        })

        try {
            const response = await api.post('/agendamentos_completos/many', recurrentReservationsData)

            toast.success("Reserva realizada com sucesso")
            console.log(response)
            const agendamentoReturn = response.data.agendamentos as AgendamentoType[]
            console.log(agendamentoReturn)

            const agendamentosIds = agendamentoReturn.map(agendamento => agendamento.id)
            const agendamentoRecorrenteObj = {
                tipo_agendamento_recorrente_id: recurrentReservationType.id,
                data_inicio: formatDateToYYYYMMDD(initialDate),
                data_fim: formatDateToYYYYMMDD(finalDate),
                agendamentos_id: agendamentosIds
            }
            console.log(agendamentoRecorrenteObj)

            const responseRecorrente = await api.post('/agendamentos_recorrentes', agendamentoRecorrenteObj)
            if (responseRecorrente.status === 201) {
                toast.success("Reserva recorrente realizada com sucesso")
            }
            console.log(responseRecorrente)

            resetValuesOfContext()
            // navigate(`/`)
            navigate('/barber/agendamento-recorrente/resumo/' + responseRecorrente.data.agendamento_recorrente.id)

        } catch (error) {
            console.log(error)
            toast.error("Ocorreu um erro ao tentar finalizar sua reserva")            
        }finally{
            hideLoadingWithDelay()
        }

        console.log(recurrentReservationsData)
        hideLoadingWithDelay()
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <NormalText bold>Finalize sua reserva</NormalText>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                <InputValue
                    type="text"
                    placeholder="Seu nome aqui"
                    value={nonRegistredClient.nome}
                    onChange={(e) => setNonRegistredClient({
                        ...nonRegistredClient,
                        nome: e.target.value
                    })}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                <InputValue
                    type="tel"
                    placeholder="1799999-9999"
                    value={nonRegistredClient.telefone}
                    onChange={(e) => setNonRegistredClient({
                        ...nonRegistredClient,
                        telefone: e.target.value
                    })}
                />
            </LabelAndValueContainer>

            {recurrentReservationsSchedules.map((schedule, index) => (
                <ResumeContainer key={index}>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Barbeiro</SmallText>
                        <NormalText color={colors.mediumGray}>{schedule.barbearia_usuario.nome}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Serviços</SmallText>
                        {selectedServices.map(service => (
                            <NormalText color={colors.mediumGray} key={service.id}>- {service.nome}</NormalText>
                        ))}
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Data hora</SmallText>
                        <NormalText color={colors.mediumGray}>{formatDateToDDMMYYYYFromString(schedule.data)} - {schedule.hora}</NormalText>
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <SmallText color={colors.mediumGray} bold>Total</SmallText>
                        <NormalText color={colors.mediumGray}>R$ {selectedServices.reduce((acc, service) => acc + service.preco, 0)}</NormalText>
                    </LabelAndValueContainer>
                </ResumeContainer>
            ))
            }

            <ConfirmationButton
                onClick={handleConfirmReservation}
            >
                <MediumText color={colors.white} bold>Confirmar reserva</MediumText>
            </ConfirmationButton>
            <GoBackButton
                onClick={() => navigate(-1)}
            >
                <MediumText bold>Voltar</MediumText>
            </GoBackButton>

        </>
    );
}