import styled from "styled-components"
import colors from "../../style/colors"

export const BarbersContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 10px;
`

type BarberImageAndNameContainerProps = {
    // backgroundColor?: string,
    selected: boolean
}

export const BarberImageAndNameContainer = styled.div<BarberImageAndNameContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 100px;
    padding: 5px;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid ${colors.lightGray};
    
    /* ${props => props.selected && `background-color: ${colors.gray};`} */
    background-color: ${props => props.selected ? colors.darkGray : colors.lightGray};
    transition: background-color 0.3s;
    border: 1px solid ${colors.gray};
    user-select: none;
    

    &:hover{
        cursor: pointer;
        /* background-color: ${colors.gray}; */
        /* border: 1px solid ${colors.gray}; */
        background-color: ${props => props.selected ? colors.darkGray : colors.gray};
    }
`

export const BarberImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid ${colors.lightGray};
`

export const BarberName = styled.span<{color?: string}>`
    font-size: 10px;
    /* color: ${colors.mediumGray}; */
    color: ${props => props.color};
    text-align: center;
    transition: color 0.3s;

    
`

export const HoursAvailable = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 10px;
    overflow-x: auto;
    padding: 0 0 10px 0;

    &::-webkit-scrollbar {

        /* display: none; */
        // style
        /* width: 5px; */
        height: 5px;
        background: ${colors.lightGray};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.gray};
        border-radius: 10px;
    }
`

export const Hour = styled.div<{ selected?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid ${colors.lightGray};
    background-color: ${colors.white};
    transition: 0.3s;

    ${props => props.selected && `
        background-color: ${colors.gray};
        // background-color: ${colors.blue};
        color: ${colors.white};
    `}

    user-select: none;

    &:hover {
        cursor: pointer;
        ${props => !props.selected && `
            // background-color: ${colors.blueHover};
            background-color: ${colors.lightGray};
        `}
    }

    &:active {
        background-color: ${colors.lightGray};
    }
`

