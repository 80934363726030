import styled from 'styled-components'
import colors from '../style/colors'

export const LabelAndValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: max-content;
    /* height: 100%; */
`

export const Label = styled.span`
    font-size: 12px;
    color: ${colors.mediumGray};
`

export const GridMenuWithTreeColumns = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
`

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 125px;
    gap: 5px;
    padding: 10px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const Services = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const Service = styled.div<{ isSelected?: boolean, disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${colors.lightGray};
    padding: 20px;
    border-radius: 5px;
    user-select: none;

    transition: background-color 0.3s;

    ${props => props.isSelected && `
        background-color: ${colors.gray};        
    `}

    ${props => props.isSelected && `
        border: 1px solid ${colors.mediumGray};  
    `}

    ${props => (!props.isSelected && !props.disabled) && `
        &:hover {
            background-color: ${colors.lightGray};
            cursor: pointer;
        }
    `}

    &:active {
        outline: 1px solid ${colors.gray} !important;
    }

    ${props => props.disabled && `
        
    `}
`

export const ServiceValueAndTimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.gray};
`

export const TotalWithReservationButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`

export const TotalsContainer = styled.div`
    display: flex;
    gap: 10px;
`

export const TotalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: max-content;
`

export const ReservationButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: ${colors.darkGray};
    cursor: pointer;
    transition: background-color 0.3s;

    user-select: none;

    &:hover {
        background-color: ${colors.mediumGray};
    }

    &:active {
        background-color: ${colors.darkGray};
        outline: 2px solid ${colors.gray} !important;
    }
`
export const GoBackButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.gray};
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 1px solid ${colors.gray};
    }
`

export const AddServiceButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    /* background-color: ${colors.lightGray}; */
    background-color: ${colors.darkGray};
    cursor: pointer;
    /* flex-direction: column; */
    gap: 5px;
    transition: background-color 0.3s;

    &:hover {
        /* background-color: ${colors.gray}; */
        background-color: ${colors.mediumGray};
    }

    &:active {
        background-color: ${colors.darkGray};
        /* background-color: ${colors.lightGray}; */
        outline: 1px solid ${colors.gray} !important;
    }
`


