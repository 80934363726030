import React, { useContext } from "react"
import colors from "../../style/colors"
import { FaStoreAlt } from "react-icons/fa"
import { ChildrenContainer, Container, HallAddress, HallContainer, HallTileContainer, HallTitle, ImageContainer } from "./style"
import backgroundImage from "../../assets/images/background.png"
import { BarbershopContext } from "../../context/babershop"

type Props = {
    children: React.ReactNode
}

export default function ImageAndContentContainer({ children }: Props) {
    const barberShopContext = useContext(BarbershopContext)
    const { barbershop, barberShopPhoto } = barberShopContext

    const apiUrl = process.env.REACT_APP_API_URL || '';

    return (
        <>
            <Container>
                {/* <ImageContainer src={process.env.REACT_APP_API_URL + barberShopPhoto?.foto}> */}
                <ImageContainer src={apiUrl + barberShopPhoto?.foto}>
                    <HallContainer>
                        <HallTileContainer>
                            <FaStoreAlt color={colors.darkGray} size={15} />
                            <HallTitle>{barbershop?.nome}</HallTitle>
                        </HallTileContainer>
                        <HallAddress>{barbershop?.endereco  }</HallAddress>
                    </HallContainer>
                </ImageContainer>                
            </Container>
            <ChildrenContainer> {children} </ChildrenContainer>
        </>
    )
}