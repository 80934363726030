import React from 'react';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';
import NormalText from '../texts/NormalText';
import styled from 'styled-components';
import colors from '../../style/colors';

const DaysOfWeekContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 10px;
`

const DayOfWeek = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: ${props => props.active ? colors.darkGray : colors.lightGray};
    color: ${colors.white};
    border-radius: 5px;
    padding: 15px;
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: ${props => props.active ? colors.mediumGray : colors.gray};
    }

    &:active {        
        outline: 1px solid ${colors.mediumGray};
    }
`

interface DaysOfWeekSelectorProps {
    days: number[];
    handleDaysOfWeek: (day: number) => void;
    title: string;
}

const DaysOfWeekSelector: React.FC<DaysOfWeekSelectorProps> = ({ days, handleDaysOfWeek, title }) => {
    const daysOfWeek = [
        { id: 1, label: 'Segunda' },
        { id: 2, label: 'Terça' },
        { id: 3, label: 'Quarta' },
        { id: 4, label: 'Quinta' },
        { id: 5, label: 'Sexta' },
        { id: 6, label: 'Sabado' },
        { id: 7, label: 'Domingo' },
    ];

    return (
        <>
            <NormalText bold>{title}</NormalText>
            <DaysOfWeekContainer>
                {daysOfWeek.map(day => (
                    <DayOfWeek
                        key={day.id}
                        active={days?.includes(day.id)}
                        onClick={() => handleDaysOfWeek(day.id)}
                    >
                        {days?.includes(day.id) ? (
                            <FaCheckSquare style={{ marginRight: '8px' }} />
                        ) : (
                            <FaSquare style={{ marginRight: '8px' }} />
                        )}
                        <NormalText
                            bold
                            color={days?.includes(day.id) ? colors.white : colors.darkGray}
                        >
                            {day.label}
                        </NormalText>
                    </DayOfWeek>
                ))}
            </DaysOfWeekContainer>
        </>
    );
};

export default DaysOfWeekSelector;