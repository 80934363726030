import styled from "styled-components"
import colors from "../../style/colors"

export const InputValue = styled.input`
    // width: 100%;
    width: 100% !important;
    border: 1px solid ${colors.gray};
    border-radius: 5px;
    padding: 20px 10px;
    color: ${colors.mediumGray};
    font-size: 16px;

    &:focus-visible {
        border: 1px solid ${colors.gray};
        /* outline: none; */
        outline: 1px solid ${colors.mediumGray};
    }

    &:active {
        border: 1px solid ${colors.gray};
    }

    &:hover {
        border: 1px solid ${colors.gray};
    }

    &::placeholder {
        color: ${colors.gray}; // replace with the color you want
        opacity: 1; // optional, change to the opacity you want
    }
        
    @media (max-width: 768px) {
        &[type='time'], &[type='date'] {
            width: 100% !important; // Ensuring width is applied on mobile devices
            box-sizing: border-box; // Ensure padding and borders are included in the element's total width
            -webkit-appearance: none;
        }
    }
`

export const CheckBoxDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }

`

export const ResumeContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 20px;
    width: 100%;
    padding: 10px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    padding: 20px;
    transition: background-color 0.3s;

    &:hover {
        // Add your hover styles here. For example:
        background-color: ${colors.gray};
    }

    &:active {
        // Add your active styles here. For example:
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`

export const WhatsAppButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #25d366;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background-color: #128c7e;
    }

    &:active {
        background-color: #128c7e;
    }
`

export const ConfirmationButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.darkGray};
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${colors.mediumGray};
    }

    &:active {
        background-color: ${colors.darkGray};
        outline: 2px solid ${colors.gray};
    }
`