import React, { useContext, useEffect, useState } from 'react';
import MediumText from '../../../components/texts/MediumText';
import styled from 'styled-components';
import { ConfirmationButton, InputValue, ResumeContainer } from '../../DoningReservation/style';
import NormalText from '../../../components/texts/NormalText';
import { AddServiceButton, LabelAndValueContainer, Separator, Service, Services, ServiceValueAndTimeContainer } from '../../globalStyles';
import colors from '../../../style/colors';
import { RecurrentReservationContext } from '../../../context/RecurrentReservation';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaPlus, FaRegUser } from 'react-icons/fa';
import Dropdown from '../../../components/DropDown';
import { api } from '../../../config/api';
import { RecurrentReservationScheduleEditType, RecurrentReservationsType, ReservationAusencesConflictListType, ReservationAusencesListType } from '../../../types/reservation';
import GenericButton from '../../../components/GenericButton';
import SmallText from '../../../components/texts/SmallText';
import DaysOfWeekSelector from '../../../components/DaysOfWeekSelector';
import { BarbersType, BarberType } from '../../../types/barber';
import { BarbershopContext } from '../../../context/babershop';
import { LoadingHook } from '../../../hooks/LoadingHook';
import LoadingFreezeScreen from '../../../components/LoadingFreezeScreen';
import { BarberImage, BarberImageAndNameContainer, BarberName, BarbersContainer } from '../../InProgressReservation/style';
import { UsuarioFotoType } from '../../../types/user';
import GenericModal from '../../../components/GenericModal';
import { AusenceContainer, DetailContainer, HourContainer, ReservationContainer, ReservationsContainer } from '../Schedules';
import { formatHourToHHMM } from '../../../functions/hour';
import { formatDateToDDMMYYYY, getHourFromDateTime } from '../../../functions/date';
import BarberSelector from '../../../components/BarberSelector';

export default function RecurrentReservation() {
    const navigate = useNavigate();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const [typesReccurent, setTypesReccurent] = useState<RecurrentReservationsType>([]);
    const [barbers, setBarbers] = useState<BarbersType>([]);
    const [barbersPhotos, setBarbersPhotos] = useState<UsuarioFotoType[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState<RecurrentReservationScheduleEditType>({
        data: '',
        hora: '',
        // barbearia_usuario_id: 0,
        barbearia_usuario: {
            barbearia_usuario_id: 0,
            nome: ''
        },
        index: 0
    });

    const barberShopContext = useContext(BarbershopContext)
    const { barbershop } = barberShopContext
    useEffect(() => {
        if (!barbershop) {
            navigate('/')
        }
    }, [barbershop, navigate])

    const recurrentReservationContext = useContext(RecurrentReservationContext);
    const {
        selectedServices,
        initialDate,
        setInitialDate,
        finalDate,
        setFinalDate,
        recurrentReservationType,
        setRecurrentReservationType,
        recurrentReservationsSchedules,
        setRecurrentReservationsSchedules,
        dayOfWeekPreference,
        setDayOfWeekPreference,
        hourPreference,
        setHourPreference,
        barberPreference,
        setBarberPreference
    } = recurrentReservationContext;

    const handleNavigateToSelectService = () => {
        navigate('servicos')
    }

    useEffect(() => {
        const getTypeRecurrent = async () => {
            try {
                const response = await api.get('/tipos_agendamento_recorrente/')
                setRecurrentReservationType(response.data[0])
                setTypesReccurent(response.data)
            } catch (error) {
                console.log(error)
            }
        }

        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${barbershop?.id}`)
                setBarbers(response.data)
                setBarberPreference([response.data[0]])
            } catch (error) {
            }
        }

        const getBarbersPhotos = async () => {
            try {
                const response = await api.get(`/usuarios_foto/barbearia/${barbershop?.id}`)
                setBarbersPhotos(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getTypeRecurrent()
        getBarbers()
        getBarbersPhotos()
        hideLoadingWithDelay()
    }, [barbershop?.id,])


    const normalizeDate = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const handleLoadReservationsByDateAndRecurrentType = () => {
        setIsLoading(true)
        setRecurrentReservationsSchedules([])
        const schedules = [];

        if (recurrentReservationType.nome === 'Semanal') {
            const diffDate = finalDate.getTime() - initialDate.getTime();
            const quantityWeeksWithoutDecimals = diffDate / (1000 * 60 * 60 * 24 * 7);
            const quantityWeeks = Math.round(quantityWeeksWithoutDecimals);


            if (initialDate.getDay() === dayOfWeekPreference) {
                schedules.push({
                    data: initialDate.toISOString().split('T')[0],
                    hora: hourPreference,
                    // barbearia_usuario_id: barberPreference.id
                    barbearia_usuario: {
                        barbearia_usuario_id : barberPreference[0].id,
                        nome: barberPreference[0].nome
                    }
                });
            } else {
                // get the next dayOfWeekPreference
                const nextDayOfWeek = initialDate.getDay() > dayOfWeekPreference ? dayOfWeekPreference + 7 : dayOfWeekPreference;
                const nextDate = new Date(initialDate);
                nextDate.setDate(initialDate.getDate() + nextDayOfWeek - initialDate.getDay());
                schedules.push({
                    data: nextDate.toISOString().split('T')[0],
                    hora: hourPreference,
                    // barbearia_usuario_id: barberPreference.id
                    barbearia_usuario: {
                        barbearia_usuario_id : barberPreference[0].id,
                        nome: barberPreference[0].nome
                    }
                });
            }

            for (let i = 1; i <= quantityWeeks; i++) {
                const currentWeekStart = new Date(initialDate);
                currentWeekStart.setDate(initialDate.getDate() + i * 7);

                const scheduleDate = new Date(currentWeekStart);
                scheduleDate.setDate(currentWeekStart.getDate() + (dayOfWeekPreference - currentWeekStart.getDay()));
                const [hour, minute] = hourPreference.split(':').map(Number);
                scheduleDate.setHours(hour, minute);

                const normalizedScheduleDate = normalizeDate(scheduleDate);
                const normalizedInitialDate = normalizeDate(initialDate);
                const normalizedFinalDate = normalizeDate(finalDate);

                if (normalizedScheduleDate >= normalizedInitialDate && normalizedScheduleDate <= normalizedFinalDate) {
                    schedules.push({
                        data: scheduleDate.toISOString().split('T')[0],
                        hora: hourPreference,
                        barbearia_usuario: {
                            barbearia_usuario_id : barberPreference[0].id,
                            nome: barberPreference[0].nome
                        }
                        // barbearia_usuario_id: barberPreference.id
                    });
                }
            }
        }

        if (recurrentReservationType.nome === 'Mensal') {
            const diffDate = finalDate.getTime() - initialDate.getTime();
            const quantityMonthsWithoutDecimals = diffDate / (1000 * 60 * 60 * 24 * 30);
            const quantityMonths = Math.round(quantityMonthsWithoutDecimals);

            const scheduleDate = new Date(initialDate);
            scheduleDate.setDate(initialDate.getDate());
            const [hour, minute] = hourPreference.split(':').map(Number);
            scheduleDate.setHours(hour, minute);

            const normalizedScheduleDate = normalizeDate(scheduleDate);
            const normalizedInitialDate = normalizeDate(initialDate);
            const normalizedFinalDate = normalizeDate(finalDate);

            if (normalizedScheduleDate >= normalizedInitialDate && normalizedScheduleDate <= normalizedFinalDate) {
                schedules.push({
                    data: scheduleDate.toISOString().split('T')[0],
                    hora: hourPreference,
                    barbearia_usuario: {
                        barbearia_usuario_id : barberPreference[0].id,
                        nome: barberPreference[0].nome
                    }
                    // barbearia_usuario_id: barberPreference.id
                });
            }

            for (let i = 1; i <= quantityMonths; i++) {
                const currentMonthStart = new Date(initialDate);
                currentMonthStart.setMonth(initialDate.getMonth() + i);

                const scheduleDate = new Date(currentMonthStart);
                scheduleDate.setDate(initialDate.getDate());
                const [hour, minute] = hourPreference.split(':').map(Number);
                scheduleDate.setHours(hour, minute);

                const normalizedScheduleDate = normalizeDate(scheduleDate);
                const normalizedInitialDate = normalizeDate(initialDate);
                const normalizedFinalDate = normalizeDate(finalDate);

                if (normalizedScheduleDate >= normalizedInitialDate && normalizedScheduleDate <= normalizedFinalDate) {
                    schedules.push({
                        data: scheduleDate.toISOString().split('T')[0],
                        hora: hourPreference,
                        barbearia_usuario: {
                            barbearia_usuario_id : barberPreference[0].id,
                            nome: barberPreference[0].nome
                        }
                        // barbearia_usuario_id: barberPreference.id
                    });
                }
            }
        }


        setRecurrentReservationsSchedules(schedules);
        hideLoadingWithDelay()
        navigate('editar')
    }

    const handleSelectedBarber = (barber: BarberType) => {
        setBarberPreference([barber])
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MediumText>Agendamento recorrente</MediumText>
            <LabelAndValueContainer>
                <NormalText color={colors.darkGray} bold>Serviços</NormalText>
                <Services>
                    {selectedServices.length === 0 && (
                        <NormalText color={colors.mediumGray} bold>Nenhum serviço foi selecionado</NormalText>
                    )}
                    {selectedServices.map((service, index) => (
                        <Service key={index}>
                            <NormalText color={colors.mediumGray} bold>{service.nome}</NormalText>
                            <ServiceValueAndTimeContainer>
                                <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                            </ServiceValueAndTimeContainer>
                        </Service>
                    ))}

                    <AddServiceButton onClick={handleNavigateToSelectService}>
                        {selectedServices.length === 0 ? (
                            <FaPlus color={colors.white} size={14} />
                        ) : (
                            <FaEdit color={colors.white} size={14} />
                        )}
                        {/* <FaPlus color={colors.white} size={20} /> */}
                        <NormalText color={colors.white}>
                            {selectedServices.length === 0 ? (
                                'Selecionar serviços'
                            ) : (
                                'Editar serviços'
                            )}
                        </NormalText>
                    </AddServiceButton>
                </Services>
            </LabelAndValueContainer>
            {selectedServices.length > 0 && (
                <>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Data inicial</NormalText>
                        <InputValue
                            type="date"
                            value={initialDate.toISOString().split('T')[0]}
                            onChange={(e) => {
                                const [year, month, day] = e.target.value.split('-').map(Number);
                                setInitialDate(new Date(year, month - 1, day));
                            }}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Data final</NormalText>
                        <InputValue
                            type="date"
                            value={finalDate.toISOString().split('T')[0]}
                            onChange={(e) => {
                                const [year, month, day] = e.target.value.split('-').map(Number);
                                setFinalDate(new Date(year, month - 1, day));
                            }}
                        />
                    </LabelAndValueContainer>

                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Tipo de intervalo de recorrencia</NormalText>
                        <Dropdown
                            options={typesReccurent.map(type => ({ label: type.nome, value: type.id.toString() }))}
                            selectedOption={{ label: recurrentReservationType.nome, value: recurrentReservationType.id.toString() }}
                            setSelectedOption={(option) => {
                                setRecurrentReservationType({ id: parseInt(option.value), nome: option.label })
                            }}
                        />
                    </LabelAndValueContainer>

                    {/* <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Escolha seu barbeiro</NormalText>
                        <BarbersContainer>
                            {barbers.map((barber: BarberType, index: number) => {
                                const barberPhoto = barbersPhotos.find((barberPhoto: UsuarioFotoType) => barberPhoto.usuario_id === barber.id)

                                return (
                                    <>
                                        {barberPhoto?.foto ? (
                                            <BarberImageAndNameContainer key={index} onClick={() => handleSelectedBarber(barber)} selected={barberPreference?.id === barber.id}>
                                                <BarberImage src={process.env.REACT_APP_API_URL + barberPhoto.foto} alt="Barber Image" />
                                                <BarberName>{barber.nome}</BarberName>
                                            </BarberImageAndNameContainer>
                                        ) : (
                                            <BarberImageAndNameContainer key={index} onClick={() => handleSelectedBarber(barber)} selected={barberPreference?.id === barber.id}>
                                                <FaRegUser color={colors.mediumGray} size={20} />
                                                <BarberName>{barber.nome}</BarberName>
                                            </BarberImageAndNameContainer>
                                        )}
                                    </>
                                )
                            })
                            }
                        </BarbersContainer>
                    </LabelAndValueContainer> */}
                    <BarberSelector
                        selectedBarbers={barberPreference}
                        setSelectedBarbers={setBarberPreference}
                        onlyOneBarber={true}
                    />
                    <Separator />

                    <DaysOfWeekSelector
                        days={[dayOfWeekPreference]}
                        handleDaysOfWeek={setDayOfWeekPreference}
                        title="Selecione o dia da semana de preferencia"
                    />

                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Hora de prefencia</NormalText>
                        <InputValue
                            type="time"
                            value={hourPreference}
                            onChange={(e) => setHourPreference(e.target.value)}
                        />
                    </LabelAndValueContainer>

                    <GenericButton
                        onClick={handleLoadReservationsByDateAndRecurrentType}
                    >
                        <NormalText>Pré carregar agendamentos</NormalText>
                    </GenericButton>
                </>
            )}
        </>
    );
}