import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import NormalText from "../texts/NormalText";

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`;

export default function GenericButton({ children, onClick }: { children: React.ReactNode, onClick: () => void }) {
    return (
        <Container onClick={onClick}>
            <NormalText>{children}</NormalText>
        </Container>
    )
}