import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../config/api'; 
import LoadingFreezeScreen from '../components/LoadingFreezeScreen';
import { LoadingHook } from '../hooks/LoadingHook';

type User = {
    id: number;
    nome: string;
    email: string;
    telefone: string;
};

type AuthContextType = {
    user: User | null;
    signIn: (email: string, password: string) => Promise<void>;
    signOut: () => void;
    validateTokenAndSetUser: () => void;
    validateUser: () => boolean;
    // loading: boolean;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: AuthProviderProps) {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);
    const {isLoading, setIsLoading, hideLoadingWithDelay} = LoadingHook();

    const checkToken = async (token: string) => {
        try {
            const response = await api.get('/token/verify', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    const validateTokenAndSetUser = async () => {
        setIsLoading(true);
        const storagedUser = localStorage.getItem('@barber:user');

        if (storagedUser) {
            setUser(JSON.parse(storagedUser));
        } else {
            setUser(null);
            hideLoadingWithDelay()
            return
            // navigate('/');                    
            // toast.error('Você precisa fazer login');
        }

        const storagedToken = localStorage.getItem('@barber:token');

        if (storagedToken) {
            const tokenIsValid = await checkToken(storagedToken);
            if (!tokenIsValid) {
                localStorage.removeItem('@barber:user');
                localStorage.removeItem('@barber:token');
                setUser(null);
                navigate('/');                    
                toast.error('Sessão expirada');
                // return;
            }
            
            api.defaults.headers.authorization = `Bearer ${storagedToken}`;
        }

        hideLoadingWithDelay()    
    };

    useEffect(() => {
        const validateAndSetUser = async () => {
            await validateTokenAndSetUser();
        };
    
        validateAndSetUser();
        // validateTokenAndSetUser();
    }, []);

    const signIn = async (email: string, password: string) => {
        try {
            // const data = {
            //     'username': email,
            //     'password': password,
            //   };

            //   const response = await api({
            //     method: 'POST',
            //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
            //     data: qs.stringify(data),
            //     url: 'https://nocorte.com/api/login',
            //   });

            const response = await api.post('/login/', {
                email: email.toLowerCase(),
                password,
            });

            // const url = 'https://nocorte.com:8080/login/';
            // const data = {
            //     email: "teste@teste.com",
            //     password: "teste"
            // };

            // const response:any = await fetch(url, {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(data)
            // });

            // if (!response) {
            //     throw new Error('Network response was not ok');
            // }
            
            if (response.data.detail){
                toast.error('Erro ao fazer login');
                return
            }

            const { user, access_token } = response.data;
            
            localStorage.setItem('@barber:user', JSON.stringify(user));
            localStorage.setItem('@barber:token', access_token);

            api.defaults.headers.authorization = `Bearer ${access_token}`;
            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

            setUser(user);
            toast.success('Logado com sucesso');
            // navigate('/');
        } catch (error) {
            toast.error('Email ou senha incorretos');
        }
    };

    const signOut = () => {
        localStorage.removeItem('@barber:user');
        localStorage.removeItem('@barber:token');
        setUser(null);
        navigate('/');
        toast.success('Deslogado com sucesso');
    };

    const validateUser = () => {
        const storagedUser = localStorage.getItem('@barber:user');
        if (!storagedUser){
            toast.error('Faça login para acessar esta página');
            navigate('/');
            return false
        }
        return true
    }

    return (
        <AuthContext.Provider value={{ 
            user,
            signIn,
            signOut,
            validateTokenAndSetUser,
            validateUser
        }}>
            <LoadingFreezeScreen isLoading={isLoading} />
            {children}
        </AuthContext.Provider>
    );
}