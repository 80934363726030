import React from "react"
import { FaRegCalendarAlt, FaStoreAlt, FaUserAlt, FaWhatsapp } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import MediumText from "../../../components/texts/MediumText"
import NormalText from "../../../components/texts/NormalText"
import colors from "../../../style/colors"
import { GridMenuWithTreeColumns, ItemContainer, MenuContainer } from "../../globalStyles"
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../hooks/LoadingHook"

export const handleGoToWhatsAppChat = () => {
    const url = "https://api.whatsapp.com/send?phone=5517996151979&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20uma%20reserva";
    window.open(url, "_blank")
}

export const handleGoToLocation = () => {
    // const url = "https://maps.app.goo.gl/JtHia6khQoipNjpR8";
    const url = "https://maps.app.goo.gl/obnY7sDvQmiPnAE47";
    window.open(url, "_blank")
}

export default function UnlogedHome() {
    const navigate = useNavigate();
    const { isLoading } = LoadingHook()

    const handleNavigate = (path: string) => {
        navigate(path)
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <MenuContainer>
                <MediumText color={colors.mediumGray}>Menu</MediumText>
                <GridMenuWithTreeColumns>
                    <ItemContainer onClick={() => handleNavigate('reserva')}>
                        <FaRegCalendarAlt size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Faça sua reserva</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={() => handleNavigate('reserva/buscar')}>
                        <FaUserAlt size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Veja sua reserva</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={handleGoToWhatsAppChat}>
                        <FaWhatsapp size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Entre em contato</NormalText>
                    </ItemContainer>
                    <ItemContainer onClick={handleGoToLocation}>
                        <FaStoreAlt size={20} color={colors.mediumGray} />
                        <NormalText color={colors.mediumGray}>Ver localização</NormalText>
                    </ItemContainer>
                </GridMenuWithTreeColumns>
            </MenuContainer>
        </>
    )
}