import React from "react";
import { Route, BrowserRouter, Routes, Outlet, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Topbar from "../components/Topbar";
import InProgressReservation from "../pages/InProgressReservation";
import ImageAndContentContainer from "../components/ImageAndContentContainer";
import SelectService from "../pages/SelectService";
import DoningReservation from "../pages/DoningReservation";
import DonedReservation from "../pages/DonedReservation";
import { BarbershopContext, BarbershopProvider } from "../context/babershop";
import { InProgressReservationProvider } from "../context/inProgressReservation";
import StepsReservation from "../pages/Reservation/StepsReservation";
import GetReservation from "../pages/GetReservation";
import { AuthContext, AuthProvider } from "../context/authContext";
import LogedHome from "../pages/Home/LogedHome";
import Schedules from "../pages/Barber/Schedules";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import ListAusences from "../pages/Barber/Ausences/ListAusences";
import Ausence from "../pages/Barber/Ausences/Ausence";
import ServiceList from "../pages/BarberShop/Services/ServicesList";
import Barbershops from "../pages/BarberShop/Barbershops";
import BarberShopHome from "../pages/BarberShop/BarberShopHome";
import Service from "../pages/BarberShop/Services/Service";
import OpeningHoursList from "../pages/BarberShop/OpeningHours/OpeningHoursList";
import OpeningHour from "../pages/BarberShop/OpeningHours/OpeningHour";
import BarbersList from "../pages/BarberShop/Barbers/BarbersList";
import InviteBarber from "../pages/BarberShop/Barbers/InviteBarber";
import BarberBarberShop from "../pages/BarberShop/Barbers/Barber";
// import { ChildrenContainer } from "../components/ImageAndContentContainer/style";
import BarberShopDetails from "../pages/BarberShop/BarberShop";
import styled from "styled-components";
import colors from "../style/colors";
import NotFound from "../pages/NotFound";
import Logo from "../components/Logo";
import RecurrentReservation from "../pages/Barber/RecurrentReservation";
import { RecurrentReservationProvider } from "../context/RecurrentReservation";
import SelectServiceRecurrent from "../pages/Barber/RecurrentReservation/SelectServicesRecurrent";
import RecurrentReservationConfirmation from "../pages/Barber/RecurrentReservation/Confirmation";
import ResumeRecurrentReservation from "../pages/Barber/RecurrentReservation/Resume";
import EditReservations from "../pages/Barber/RecurrentReservation/EditReservations";

const MainContainer = styled.div`
    width: 100%;
    background-color: ${colors.white};

    @media (min-width: 768px) { /* Show on desktop */
        /* padding: 10px; */
        margin: 40px 0px;
        max-width: 430px;
        border-radius: 20px;
        border: 1px solid #e0e0e0;
        box-shadow: 0px 0px 5px 0px #e0e0e0;
        /* height: 50%; */
    }
`

const BackgroundContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* align-items: ; */
  justify-content: center;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  /* height: 100%; */
  /* height: 100%; */
  /* max-height: 100vh; */
`

const GeneralBaseLayout = () => {
    return (
        <>
            <BackgroundContainer>
                <MainContainer>
                    <Topbar />
                    <ImageAndContentContainer>
                        <Outlet />
                        {/* <Logo/> */}
                    </ImageAndContentContainer>
                </MainContainer>
            </BackgroundContainer>

        </>
    )
}

const NotFoundPage = () => {
    return (
        <>
            <BackgroundContainer>
                <MainContainer>
                    <Topbar />
                    <ImageAndContentContainer>
                        <NotFound />
                    </ImageAndContentContainer>
                </MainContainer>
            </BackgroundContainer>
        </>
    )
}


const ProfileLayout = () => {
    const { validateTokenAndSetUser } = React.useContext(AuthContext);
    const [isValidationComplete, setIsValidationComplete] = React.useState(false);

    React.useEffect(() => {
        validateTokenAndSetUser();
        setIsValidationComplete(true);
    }, []);

    if (!isValidationComplete) {
        return null; // or a loading spinner
    }

    return (
        <>
            <BackgroundContainer>
                <MainContainer>
                    <Topbar />
                    <Outlet />
                </MainContainer>
            </BackgroundContainer >
        </>
    )
}

const BaseLayoutProtected = () => {
    const { validateTokenAndSetUser } = React.useContext(AuthContext);
    const [isValidationComplete, setIsValidationComplete] = React.useState(false);

    React.useEffect(() => {
        validateTokenAndSetUser();
        setIsValidationComplete(true);
    }, []);

    if (!isValidationComplete) {
        return null; // or a loading spinner
    }

    return (
        <>
            <BackgroundContainer>
                <MainContainer>
                    <Topbar />
                    <ImageAndContentContainer>
                        <Outlet />
                    </ImageAndContentContainer>
                </MainContainer>
            </BackgroundContainer>
        </>
    )
}

export default function GeneralRoutes() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <BarbershopProvider>
                    <InProgressReservationProvider>
                        <RecurrentReservationProvider>
                            <Routes >
                                <Route path="/" element={<GeneralBaseLayout />} >
                                    <Route index element={<Home />} />
                                    <Route path="/reserva" element={<InProgressReservation />} />
                                    <Route path="/reserva/servico" element={<SelectService />} />
                                    <Route path="/reserva/concluir" element={<DoningReservation />} />
                                    <Route path="/reserva/concluida/:id" element={<DonedReservation />} />
                                    <Route path="/reserva/buscar" element={<GetReservation />} />
                                </Route>
                                <Route path="barber" element={<BaseLayoutProtected />} >
                                    <Route index element={<LogedHome />} />
                                    <Route path="reservas" element={<Schedules />} />
                                    {/* <Route path="agendamento-recorrente" element={<RecurrentReservation />} /> */}
                                    <Route path="agendamento-recorrente" >
                                        <Route index element={<RecurrentReservation />} />
                                        <Route path='servicos' element={<SelectServiceRecurrent />} />
                                        <Route path='editar' element={<EditReservations />} />
                                        <Route path='confirmacao' element={<RecurrentReservationConfirmation />} />
                                        <Route path='resumo/:id' element={<ResumeRecurrentReservation />} />
                                    </Route>

                                    <Route path="ausencias" >
                                        <Route index element={<ListAusences />} />
                                        <Route path="ausencia" element={<Ausence />} />
                                        <Route path="ausencia/:id" element={<Ausence />} />
                                    </Route>
                                </Route>
                                <Route path="barbearias" element={<BaseLayoutProtected />} >
                                    <Route index element={<Barbershops />} />
                                    <Route path="barbearia" element={<BarberShopHome />} />
                                    <Route path='barbearia/:id_barbearia' >
                                        <Route index element={<BarberShopHome />} />
                                        <Route path="detalhes" element={<BarberShopDetails />} />
                                        <Route path="servicos"  >
                                            <Route index element={<ServiceList />} />
                                            <Route path="servico" >
                                                <Route index element={<Service />} />
                                                <Route path=":id_service" element={<Service />} />
                                            </Route>
                                        </Route>
                                        <Route path="horarios">
                                            <Route index element={<OpeningHoursList />} />
                                            <Route path="horario/:dia" >
                                                <Route index element={<OpeningHour />} />
                                                <Route path=":id_horario" element={<OpeningHour />} />
                                            </Route>
                                        </Route>
                                        <Route path="barbeiros" >
                                            <Route index element={<BarbersList />} />
                                            <Route path="convite" element={<InviteBarber />} />
                                            <Route path="barbeiro/:id_barber" element={<BarberBarberShop />} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="login" element={<GeneralBaseLayout />} >
                                    <Route index element={<Login />} />
                                </Route>
                                <Route path="profile" element={<ProfileLayout />} >
                                    <Route index element={<Profile />} />
                                </Route>
                                <Route path="*" element={<NotFoundPage />} />
                                {/* <Route path="*" element={<GeneralBaseLayout />}>
                                <Route index element={<NotFound />} />
                            </Route> */}
                            </Routes>

                        </RecurrentReservationProvider>
                    </InProgressReservationProvider>
                </BarbershopProvider>
            </AuthProvider>
        </BrowserRouter>
    )
}